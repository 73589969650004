import React from "react";
import { string, object, node, arrayOf, oneOfType } from "prop-types";

export default function Flex({
  children,
  direction,
  alignItems,
  justifyContent,
  flexWrap,
  style,
  className,
  ...props
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        alignItems,
        justifyContent,
        flexWrap,
        ...style,
      }}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
}

Flex.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  direction: string,
  flex: string,
  alignItems: string,
  justifyContent: string,
  flexWrap: string,
  style: object,
  className: string,
};

Flex.defaultProps = {
  direction: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "nowrap",
  style: {},
  className: "",
};
