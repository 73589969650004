import {
  Card,
  notification,
  Spin,
  Typography,
  Row,
  Col,
  Tag,
  Form,
  Select,
  Input,
  Button,
  Collapse,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { getAllAdmins } from "../services/admins.services";
import { useAuth } from "../services/authentication.services";
import {
  createGroup,
  getAllLessons,
  getLessonGroups,
} from "../services/lessons.services";

const { Title } = Typography;
const { Option } = Select;

const StudentCard = ({ title, embed }, idx) => {
  console.log(title, embed);
  return (
    <Collapse collapsible={true} style={{ marginBottom: 15, width: "100%" }}>
      <Collapse.Panel header={title} key={idx}>
        {
          <div
            className="video-container"
            dangerouslySetInnerHTML={{ __html: embed }}
          ></div>
        }
      </Collapse.Panel>
    </Collapse>
  );
};

export default function Home() {
  const [isAdmin, setIsAdmin] = useState(null);

  const [status, setStatus] = useState("loading");
  const { user } = useAuth();
  const [selectedCourse, setSelectedCourse] = useState({});
  const [showStudents, setShowStudents] = useState(false);
  const [groups, setGroups] = useState([]);
  const [activeGroup, setActiveGroup] = useState({});
  const [modalVisibility, setModalVisibility] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [groupForm] = Form.useForm();
  const [courses, setCourses] = useState([]);

  useEffect(() => {});

  useEffect(() => {
    (async () => {
      setStatus("loading");
      const admins = await getAllAdmins();
      const admin = admins.find(
        (a) =>
          (a?.email || "").trim().toLowerCase() === user.email.toLowerCase()
      );
      const res = await getLessonGroups(
        { email: user.email },
        { isAdmin: Boolean(admin?.email), course: admin?.course }
      );
      setIsAdmin(Boolean(admin?.email));
      setGroups(res);
      setStatus("success");
    })();
  }, [confirmLoading]);

  useEffect(() => {
    getAllLessons().then((r) => {
      const courses = Object.entries(r).map(([key, item]) => ({
        value: key,
        label: item.name,
        groups: item.groups,
      }));

      setCourses(courses);
    });
  }, []);

  const onCreateGroup = async (info) => {
    setConfirmLoading(true);
    await createGroup(info);
    groupForm.resetFields();
    setConfirmLoading(false);
    setModalVisibility(false);
  };

  const sortedGroups = groups.slice().sort((a, b) => a.order - b.order);

  return (
    <Spin spinning={status === "loading"}>
      <h1>Hi {user.displayName || user.email}</h1>

      <h4>My Passcode: {user.uid}</h4>
      <Row gutter={16}>
        <Col span={24}>
          {status === "success" ? (
            <>
              {sortedGroups.map((group) => (
                <Tag
                  onClick={() => setActiveGroup(group)}
                  style={{
                    cursor: "pointer",
                    padding: 20,
                    margin: "20px 10px 20px 0",
                  }}
                  color={activeGroup.name === group.name ? "black" : ""}
                >
                  {group.name}
                </Tag>
              ))}

              {isAdmin && (
                <Tag
                  style={{
                    cursor: "pointer",
                    padding: 20,
                  }}
                  color="processing"
                  onClick={() => setModalVisibility(true)}
                >
                  Add a group
                </Tag>
              )}
            </>
          ) : (
            status === "error" && <Title>Sorry, you're not our student!</Title>
          )}

          {isAdmin && !isEmpty(activeGroup) && (
            <Collapse style={{ marginTop: 20 }} collapsible={true}>
              <Collapse.Panel header="Student List" key={120}>
                {Object.values(activeGroup?.students || []).map(({ email }) => (
                  <Tag>{email}</Tag>
                ))}
              </Collapse.Panel>
            </Collapse>
          )}

          <Row style={{ marginTop: 40 }}>
            <Col span={24} lg={14}>
              {Object.values(activeGroup?.videos || []).map(
                ({ title, embed }) => (
                  <StudentCard title={title} embed={embed} />
                )
              )}
            </Col>
          </Row>

          <Modal
            onCancel={() => setModalVisibility(false)}
            title="Add a group"
            visible={modalVisibility}
            confirmLoading={confirmLoading}
            form={groupForm}
            onOk={() => {
              groupForm.validateFields().then((values) => {
                onCreateGroup(values);
              });
            }}
          >
            <Form form={groupForm} layout="vertical">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                name="course"
                label="Course"
              >
                <Select placeholder="Course Name">
                  {courses.map(({ value, label }) => {
                    return (
                      <Option key={value} value={value}>
                        {label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                name="group"
                label="Group Name"
              >
                <Input placeholder="Group Name" />
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </Spin>
  );
}
