import { cleanKey } from "../helpers/helpers";
import firebase, { database } from "./firebase.services";

export function getAllLessons() {
  return database
    .ref("/lessons")
    .once("value")
    .then((snapshot) => snapshot.val());
}

export function addStudentToGroup({ course, group }, student) {
  return firebase
    .database()
    .ref(
      "lessons/" +
        cleanKey(course) +
        "/groups/" +
        cleanKey(group) +
        "/students/" +
        cleanKey(student.id)
    )
    .set({
      email: student.email,
    });
}

export function createLesson({ course, title, embed, group }) {
  return firebase
    .database()
    .ref(
      "lessons/" +
        cleanKey(course) +
        "/groups/" +
        cleanKey(group) +
        "/videos/" +
        cleanKey(title)
    )
    .set({
      title,
      embed,
    })
    .catch((error) => {});
}

export async function getLessonGroups({ email }, { isAdmin, course }) {
  const lessons = await firebase
    .database()
    .ref("/lessons/")
    .once("value")
    .then((snapshot) => snapshot.val());

  if (isAdmin) {
    let adminGroups = [];

    Object.entries(lessons).forEach(([key, { groups, name }]) => {
      if (key === course) {
        adminGroups.push(...(Object.values(groups) || []));
      }
    });

    return adminGroups;
  }

  const studentGroups = [];
  Object.values(lessons).forEach(({ groups }) => {
    Object.values(groups).forEach((group) => {
      if (Object.values(group.students || {}).find((s) => s.email === email)) {
        studentGroups.push(group);
      }
    });
  });

  return studentGroups;
}

export async function createGroup({ group, course }) {
  return firebase
    .database()
    .ref("/lessons/" + cleanKey(course) + "/groups/" + cleanKey(group))
    .set({
      name: group,
    });
}

export async function addStudent({ email, idx, group, course }) {
  return firebase
    .database()
    .ref(
      "/lessons/" +
        cleanKey(course) +
        "/groups/" +
        cleanKey(group) +
        "/students/" +
        idx
    )
    .set({
      email,
    });
}
