import { Spin } from "antd";
import { useLayoutEffect, useState, createContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Routes } from "./helpers/routes.helpers";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Homeworks from "./pages/Homeworks";
import { getAllAdmins } from "./services/admins.services";
import { useAuth } from "./services/authentication.services";
import Layout from "./components/Layout/Layout/Layout";
import Absentees from "./pages/Absentees";

export const AdminContext = createContext();

function App() {
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [status, setStatus] = useState("idle");

  useLayoutEffect(() => {
    setStatus("loading");
    getAllAdmins()
      .then((admins) => {
        const isAdmin = admins.find((a) => a?.email === user.email);
        setStatus("idle");
        setIsAdmin(isAdmin);
      })
      .catch(() => {
        setStatus("idle");
      });
  }, [user?.email]);

  return (
    <Router>
      <AdminContext.Provider value={isAdmin}>
        {user === null || status === "loading" ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Switch>
            <div style={{ marging: 30 }}>
              <Route exact path={Routes.home()}>
                {user === false ? (
                  <Redirect to={Routes.login()} />
                ) : (
                  <Layout isAdmin={isAdmin} style={{ margin: 30 }}>
                    <Home />
                  </Layout>
                )}
              </Route>
              <Route exact path={Routes.admin()}>
                {user === false ? (
                  <Redirect to={Routes.login()} />
                ) : (
                  <Layout isAdmin={isAdmin} style={{ margin: 30 }}>
                    <Admin />
                  </Layout>
                )}
              </Route>
              <Route exact path={Routes.absentees()}>
                {user === false ? (
                  <Redirect to={Routes.login()} />
                ) : (
                  <Layout isAdmin={isAdmin} style={{ margin: 30 }}>
                    <Absentees />
                  </Layout>
                )}
              </Route>
              <Route exact path={Routes.homeworks()}>
                {user === false ? (
                  <Redirect to={Routes.login()} />
                ) : (
                  <Layout isAdmin={isAdmin} style={{ margin: 30 }}>
                    <Homeworks />
                  </Layout>
                )}
              </Route>
              <Route exact path={Routes.login()}>
                <Login />
              </Route>
            </div>
          </Switch>
        )}
      </AdminContext.Provider>
    </Router>
  );
}

export default App;
