import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  notification,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../services/authentication.services";

const hwk1 = [
  {
    "Student Name": "Ruzanio",
    "Grade (/100)": 100,
    "Feedback And Comments":
      'Great Things: - Great examples, digestable explanations. \n\nThings You Can Improve: \nQ1) How do we know that the customers are making the right requests? Are they the only one that need this, or this is something many customers need as well.\nQ5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) Please try to bring examples outside of the lecture discussions.\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)',
  },
  {
    "Student Name": "Vrezh Oganisyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      'Great Things: - Great examples, digestable explanations. \n\nThings You Can Improve: \nQ1) How do we know that the customers are making the right requests? Are they the only one that need this, or this is something many customers need as well.\nQ5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) Please try to bring examples outside of the lecture discussions.\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)',
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      'Great Things: - Great examples, digestable explanations. \n\nThings You Can Improve: \nQ1) How do we know that the customers are making the right requests? Are they the only one that need this, or this is something many customers need as well.\nQ5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) Please try to bring examples outside of the lecture discussions.\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)',
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q1) How about the requirements that come from the business without the proper analysis of their value propositions and discussions with the product teams. \nQ5) Just a tip: highlight the severity level of the problems that Maslow's pyramid helps us as product managers to concentrate on. \nQ8) Is this a question or a statement?\nQ9) In the future, try brining real-world examples.\nQ10) Maybe you wanted to say PaaS?",
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 89,
    "Feedback And Comments":
      "Q1) How do we know that the customers are making the right requests? Are they the only one that need this, or this is something many customers need as well.\nQ2) Why output-based roadmaps can be evil? What are the issues associated with output-based roadmaps in this stage?\nQ3) How about the issues associated with employee initiatives (the business doesn't care about it) and the issues associated with micromanagement. \nQ5) Instagram has higher valuation that Yerevan City, but this doesn't mean that the human needs satisfied by instagram have higher priority than the human needs satisfied by Yerevan City.  The \nMaslow pyramid says that if some need from a block bellow is not satisfied well enough, humans don't concentrate too much on the needs above. \nQ9) Maybe you meant reliability? :)\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer\nto build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Amazing work and thank you for your honesty :)",
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q2) Why output-based roadmaps can be evil? What are the issues associated with output-based roadmaps in this stage?\nQ6) Maybe you wanted to say HUX? :) Thanks for being honest :D\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer\nto build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Great job but pleeeeeease be short in your answers :)",
  },
  {
    "Student Name": "Miqayel Nazaryan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q2) Everything is great, you just need to add the strict deadline related issue.\nQ5)Maslow pyramid is more about finding pain points and concentrating on them to find solutions. \nAlso, it has a standart structure to follow.",
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Excellent! :)",
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) How about Product Management role relaed issues in this model? Yes, following every customer request is not a right option, but what about considering some of them? \nQ4) How about micromanagement in Taylorian management approach?\nQ5) The Pyramid does not change, we just discover our users and see which stage of needs we are going to work on.",
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Great Things: - Short and to the point answers \n\nQ7) overall appearance of the car is an augmented product\nQ10) Platform products can also be considered as  operational environments for some solutions.",
  },
  {
    "Student Name": "Ani Davtyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q4) What about monetary reward and routine work?\nQ6) What about Info architecture and technology? \nQ8) Great example!",
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      'Q1) "All the requests from the costumers should be discussed and prioritized" - not all of them ;) sometimes users themselves don\'t know what they want\nQ2) What about outbut-based roadmaps? Why output-based roadmaps can be evil? What are the issues associated with output-based roadmaps in this stage?\nQ5) Great answer!\nQ6) Please mention the factors: Info architecture and technology, CS support',
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q6) What about Info architecture?",
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q1) Short note: startup CEOs are not always product managers: it's is a common case but not a must. \nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q1) How about Product Management role relaed issues in this model?\nQ5) What is your outcome from Maslow's Pyramide as a product manager?\nQ6) Please mention the factors: Info architecture and technology, CS support",
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Great Things: - Short and to the point answers \n\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Great Things: - Short and to the point answers \n\nQ1) In old approach most of the ideas do not come from customers. The point is here that we need to consider some valuable ideas but not all of them. \nQ7) Speed for vehicle is an actual product",
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Great Things: - Short and to the point answers \nQ7) Being colorful is not a core feature of a macbook",
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Girl, you rock!",
  },
  {
    "Student Name": "Tatevik Sevoyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Great Things: - Structured, short and to the point answers \n\nQ7) The core product of manu.am is it's delivery",
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Great Things: - short and to the point answers \nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Great job!",
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q2) What about resource shortage? \nQ5) I would put it this way: \"The hypothesis is that people are GENERALLY DON'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones.",
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Bold 100! Good job!",
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Tatev Tumanyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Awesome!",
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1)  Project managers  work only on delivery part\nQ2) What about deadlines and shortage of resources?\nQ4) What about monetary reward and innovation?",
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q1) What about CEOs being on top of the decision making proccess and using PMs only for delivery?\nQ2) What about resource shortage?\nQ9) Great answer\nQ10)Yes, platform products can be considered as base tools that will be used for developing other products, but they are also enviroments",
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q1) The ideation stage in the traditional approach of Product Management DID NOT focused on customers: it focused mostly on gut feeling \nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ7) Messaging, taking pictures are not the core features of iPhone. They are a part of the actual product\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ6) Please mention the factors: Info architecture and technology, CS support\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Congrats for a great job!",
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      'Q4) What about micromanagement? \nQ5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) Example is awesome but factors are not mentioned\nQ7) " the augmented product is that you use both of them(why you bought it and what you use), Do you mean using iwatch for calls and hour checking. If yes, then this part is wrong. \nQ10) Specifics of platform product are not mentioned. Please check the presentation.',
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Great job!",
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q5) I would put it this way: \"The hypothesis is that people are GENERALLY DON'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones.",
  },
  {
    "Student Name": "Christine Petrosyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q1) What about CEOs being on top of the decision making proccess and using PMs only for delivery?\nQ5) Okay, but what about product management?",
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) What about customer requests?\nQ2) What about resource shortage?\nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ6) Only one factor is mentioned",
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q2) What about resource shortage? \nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ6) Only one factor is mentioned\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Great Things: - short and to the point answers \n\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Meri Khachatryan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 99,
    "Feedback And Comments": "Q4) What about monetary reward?",
  },
  {
    "Student Name": "Astghik Malkhasyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ6) Only one factor is mentioned",
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q1) What about CEO being the main decision maker?",
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q4) What about monetary reward? \nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q4) What about micromanagement, monetary reward, routine work, initiatives?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Great job!",
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q1) What about CEOs being on top of the decision making proccess and using PMs only for delivery?\nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Lilit Petrosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Girl, you rock!",
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Good job, but pleeeeease be short in your answers",
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q4) What about monetary reward? \nQ5) What about product management?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Great job!\n\nQ7) Really enjoyed reading :D",
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q2) What about resource shortage and Outcome based strategy?\nQ7) Catch-up feature for Ucom is an actual product not the core one\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Amalya Fljyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Girl, you are awesome!",
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Reaaly great job!",
  },
  {
    "Student Name": "Murad Vardzelyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q2) Right, you could also talk about resource shortage and outcome based strategy",
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Bold 100! Good job!",
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Well done!",
  },
  {
    "Student Name": "Lilith Dallaqyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q2) Right, you could also talk about resource shortage and outcome based strategy \nQ6) Only one factor is mentioned",
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q1)What about CEO and Product Manager role?\nQ2) What about time shortage?\nQ4) What about monetary reward?\nQ6) Only one factor is mentioned\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Levon Ghevondyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q2) What about outcome based roadmap and resource shortage?\nQ4) What abot monetary reward?",
  },
  {
    "Student Name": "David Asichyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q5) Why does PM need Maslow's Pyramid\nQ6) What about other factors?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Bold 100 but you should also know that \nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": 98,
    "Feedback And Comments": "Q6) What about other factors?",
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q4) What about monetary reward?",
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q4) What about monetary reward?\nQ6) What about other factors?",
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      'Q5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) What about other factors?',
  },
  {
    "Student Name": "Rita Vardaynayn",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Great job!\nQ5) I would put it this way: \"The hypothesis is that people are GENERALLY DON'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones.",
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 98,
    "Feedback And Comments": "Q6) What about other factors?",
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) What about the customer ideas?\nQ2) What about monetary reward?\nQ6) What about other factors?",
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q2) What about outcome based roadmap?\nQ4) What about monetary reward, innitiatives, routine work?\nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ7) The core product of Smarttraining.com is trainging opportunity, the one you have mentioned is an actual product\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Haykuhi Hovhannisyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      'Q5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) What about other factors?',
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Girl you rock!",
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      'Q2) What about outcome based roadmap, deadlines, resurce shortage?\nQ4) What about monetary reward?\nQ5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)',
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Great job!\nQ5) I would put it this way: \"The hypothesis is that people are GENERALLY DON'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones.",
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q2) What about outcome based roadmap?\nQ10) What about examples ?",
  },
  {
    "Student Name": "Neli Vardanyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Well done!",
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Pleaaaaaaase be short and to the point in your answers",
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 85,
    "Feedback And Comments":
      "Q6) What about other factors ?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.) also they can be considered as enviroments for other products",
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 47,
    "Feedback And Comments":
      "Q1) What about the customer ideas?\nQ6 to Q10 no answers",
  },
  {
    "Student Name": "Արամ Անդրեասյան",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q1) What about the customer ideas?\nQ2) What about deadlines and resources?\nQ4) What about monetary reward and initiatives? Here you just need to talk about micromanagement, monetary reward, initiatives and repeatative work\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 99,
    "Feedback And Comments": "Q1) What about the customer ideas?",
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Well done!",
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) What about the customer ideas and C-level management?",
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 67,
    "Feedback And Comments":
      "Q2) What about deadlines and resources?\nQ3) The answer is Demonstration\nQ4) What about micromanagement?\nQ5) The pyramid helps to know what specific problem we are going to solve and why.\nQ6) You brought the factors, but what about examples?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q2) What about outcome based roadmaps? \nQ6) What about other factors?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)",
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q6) The examples are great but the factors should had been mentioned seperately as well",
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 91,
    "Feedback And Comments":
      "Q4) The Taylorian management approach approach was not discribed correctly. Here you just need to talk about micromanagement, monetary reward, initiatives and repeatative work",
  },
  {
    "Student Name": "Gayane Papinyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Great job!",
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Well done!",
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Great job!\nQ5) I would put it this way: \"The hypothesis is that people are GENERALLY DON'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones.",
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) What about monetary reward?\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.) also they can be considered as enviroments for other products",
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q4) What about initiatives? \nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.\nQ6) What about other factors?\nQ7) The core product of TV is not Watching a TV: it is getting an information",
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.) also they can be considered as enviroments for other products",
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q2) What about resource shortage?\nQ4) What about monetary rewards?",
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 98,
    "Feedback And Comments": "Q4) What about routine work and initiatives?",
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 57,
    "Feedback And Comments":
      "Q1) What about the customer ideas?\nQ4) Seems like you have copy pasted the answer for the second question\nQ5) The same \nQ6) The factors are not mentioned in general \nQ8) This one is a copy paste as well\nQ10) This one is missing",
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q6) What about other factors rather than UI/UX solutions?\nQ10) Platform products are also enviroments for other products",
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Girl you are amazing!",
  },
  {
    "Student Name": "Janna Lalayan",
    "Grade (/100)": 98,
    "Feedback And Comments": "Q6) What about other factors?",
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q2) What about outcome based roadmaps?\nQ4) What about monetary reward and initiatives?\nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Awesome job!",
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Girl you rock!",
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Excelent!",
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Awesome job!\nQ5) Product manager does not have to start solving all of the problems from the pyramid. He/She just needs to know what specific problem she is going to solve and why.",
  },
];

const hwk2 = [
  {
    "Student Name": "Ruzanio",
    "Grade (/100)": 100,
    "Feedback And Comments":
      'Great Things: - Great examples, digestable explanations. \n\nThings You Can Improve: \nQ1) How do we know that the customers are making the right requests? Are they the only one that need this, or this is something many customers need as well.\nQ5) I would put it this way: "The hypothesis is that people are GENERALLY DON\'T PRIORIATIZE the needs at the top of the pyramid until they reach the underlying ones."\nQ6) Please try to bring examples outside of the lecture discussions.\nQ10) Platform products can be considered as base tools that will be used for developing other products. For example, a platform product can provide an API, which can be used for the customer to build a new features specific for their needs (in this scenario, customer builds those features.)',
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 81,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side. Maybe, at the beginning of the operations, the store really cares about the growth.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) I would recommend on concentrating on one company and try to explore more about how does that organization function.",
  },
  {
    "Student Name": "Miqayel Nazaryan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      'Q2) "using the password manager when you forgot your password" I am not sure that this is a recurring occation as you might never forget the password you set up previously.  I is a rare-personal occasion.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side. One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don\'t care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.',
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      'Q3) "psychographic" is psychologycal not physical. You have described the physical aspect and not the psychological',
  },
  {
    "Student Name": "Lilith Dallakyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important.",
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 89,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ5) Never ever lower the price to win in read ocean! 🙂There are a lot of big sharks who can give their products for free just to show that your product is nothing compared to theirs.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ8) Great answer! 😎",
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important.",
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q2) Recuring needs are something we deal with on a repeating but not often: like b-day.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Awesome job girl! 🤩🚀",
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": 81,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) You should descirbe the organisation product management structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      'Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) The example for the third part is missing. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) "Group PM (individual contributor) – Ուզում են դառնալ PM-ներ (how long they want)." The Group PMs are the ones who know their product very well and yjey are just mentors. The goal of Group PM is not  becoming a usual PM.\nPleaaaase be short in your answers and just give to the point answers 🙂',
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) Awesome!",
  },
  {
    "Student Name": "Tatev Tumanyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ2) Birthday is recurring: it happens every year 😇\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Pleaaaaase be short in your answers!\nQ1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ4) We need more data to see why people use Yandex and not GG.  Also w/hen discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM. Also Growth PM is a Product Manager not a Project Manager 🙂\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nThank you for your short answers! You are awesome 🚀",
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again",
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Anna Muradyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nThaaaank you for the short answers 🤩",
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) You should descirbe the organisation product mangment structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\n Q5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nAnyway other answers are awesome! Keep going! 🤩",
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Rare-personal: like buying something for your wedding. Black friday is a Recurring-personal case as it happens every year.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Amazing! 🤩🤩",
  },
  {
    "Student Name": "Lilit Petrosyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 87,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 86,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) You could just create a tree in another doc and share the link  in this one.",
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Awesome job girl! 🤩🚀",
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nAnyway, you are awesome! 😎",
  },
  {
    "Student Name": "Meri Khachatryan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Haykuhi Hovhannisyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Murad Vardzelyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market.",
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.",
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nGood job!",
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Astghik Malkhasyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again. \nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Rare-personal: like buying something for your wedding. Christmas is a Recurring-personal case as it happens every year.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Khachik Tаkiryan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market.",
  },
  {
    "Student Name": "Cristine Sargsyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market.",
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important.",
  },
  {
    "Student Name": "Ani Davtyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ3) The example is missing\nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 87,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Rare-personal: like buying something for your wedding. \"New Year eve\" is a Recurring-personal case as it happens every year.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) You should descirbe the organisation product management structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail \nQ10) You should descirbe the organisation product management structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Anzhela Gasparyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nGreat job! 😍",
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ10) You should descirbe the organisation product management structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Amazing Hrach jan 🤩",
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ10) You should descirbe the organisation product management structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": "",
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      'Q2) Rare-personal: like buying something for your wedding. "New Year eve" is a Recurring-personal case as it happens every year.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.',
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) Awesome! but only the roles are mentioned. The description should be added too.",
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nGreat job! 😍",
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) You should descirbe the organisation product management structure (VP of product, Group PM, APM etc). The one you have mentioned is the general orgaznisation structure.",
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 68,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) The answer is missing\nQ9) The answer is too general\nQ10) The answer is missing",
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 44,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ3 and Q6, Q7, Q8, Q9 - The answers are missing \nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important.",
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ3) Yes, The psychographic attributes of a customer explains how people behave, but what is behavioral atribute? \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 82,
    "Feedback And Comments":
      "Q1) What about the second part of the question? Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q7) We can ask for a parent email if we need it \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) It would be better to talk more about PM roles (Group PM, APM etc)",
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 82,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important.",
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 81,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ7) The GBPR requirement is not mentioned\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) You just need to concentrate on PM roles in the office for this question and explain each of them.",
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Davit Asichyan",
    "Grade (/100)": 61,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ3, Q9 and Q10 are missing\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Neli Vardanyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Christine Petrosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1)Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 85,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nPlease write in Armenian or English 🙂Do not combine latine keybord with Armenian language",
  },
  {
    "Student Name": "Aram Andreasyan",
    "Grade (/100)": 72,
    "Feedback And Comments":
      "Q1)Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) The text is too general. Nothing about  universal, recurring-personal, and rare-personal occasions was mentioned\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ7 and Q10) Thank you ^__^",
  },
  {
    "Student Name": "Amalya Fljyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 84,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM. Product Manager decision making scope depends on the company.\nQ7) How would you avoid issues coming from the reuqirement you have mentioned requirement?\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ5) When we are operating the red ocean, we pretty much have the first part of the product/market fit covered - the severity of the problem. If the problem was not important, then we wouldn't have a red ocean. Now, in this case, what we still have to do is to prove that our solution is competitive among the existing solutions in the market. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Gayane Khachatryan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Janna Lalayan",
    "Grade (/100)": 86,
    "Feedback And Comments":
      "Q3) You should have taken a product and thought of hypothetical behavioral and psychographic attributes\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) 😞",
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM",
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) What about the role descriptions? ☺️",
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ3) You should have taken a product and thought of hypothetical behavioral and psychographic attributes\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ3) You should have taken a product and thought of hypothetical behavioral and psychographic attributes\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 88,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 68,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9 and Q10 are missing",
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 67,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ7, Q8, Q10 -",
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": 94,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ10) What about the role descriptions? ☺️",
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 92,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 96,
    "Feedback And Comments":
      "Q4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side.",
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 84,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ3) You should have taken a product and thought of hypothetical behavioral and psychographic attributes\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ9) Depends on the case i.e. emerging companies can't always use medium bets, if they do so they will most likely fail",
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 58,
    "Feedback And Comments":
      "Q1) Sometimes you have to go either this side or that side. For example, the investor asked you to show some pop ups. Your users will not like that but you should do that to get more money for your business. When you get the money you can turn off the pop ups and start keeping the balance again.\nQ2) Universal needs are something we deal with on a daily/weekly/monthly basis. Such as buying a bread.\nQ3) You should have taken a product and thought of hypothetical behavioral and psychographic attributes.\nQ4) When discussing vanity vs. actionable metrics, make sure to put yourself in the safe side.  One metric can be actionable in one case and venity in another. Please bring examples with cases i.e.  when talking about retention rate we don't care about aquisition rate, but when wanting to higher the amount of people who use our product aqusition rate is important. \nQ6) Growth PMs can own a part of a product, such as the landing page, the blog, and the onboarding flow, but general PMs are responsible for the strategy and the GPM role is not a priority compared to PM\nQ8) Strategy is a long-term process. If you bring a demand to the product, and there is no supply, the demand will bounce. Hence, you should concentrate on understanding how would you bring/onboard customers from the demand side before organizing any strong marketing effort for bringing the supply side. \nQ5 and Q10 are missing",
  },
];

const hwk3 = [
  {
    "Student Name": "Vrezh Oganisyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "🧐",
  },
  {
    "Student Name": "Ruzanio",
    "Grade (/100)": 100,
    "Feedback And Comments": "🧐",
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 78,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ5) Maybe there was a reason for the organizational hierarchy. Like C-level management thought of creating some barrier between flat level in order to concentrate on the most actionable activities. \nQ6) Risks can be any sort of product lifecycle model. You can just use Code&Fix for high risk tickets. \nQ8) This is more of a business viability risk. You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG \nalgorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally. \nQ10) Աչքիս վրա ;)",
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 93,
    "Feedback And Comments":
      "Q5) Is the last one really muda, or just an annoying management habit? Also, you didn't answer the second part of the question.\nQ6) In the mid-development phase, you can still do with Kanban. But at the end of the cycle, you are right because you are pretty much done. \nQ7) That's Steve Blank's quote. Rafa was just his student. :D",
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q9) You need to talk here about the product team not the product management structure. You could have mentioned the PM, Designer, Developer etc: people who come together to discuss the the problems users face and upcoming solutions. \nQ10) Awesome! Great job",
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ10) Yes, you are awesome ❤️",
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG till you have time.\nQ10) Awesome. I had a similar experience when attending the courses of Rafa and it completely paid off when I got a job as a Product Manager ☺️ (It's Shushanik :D)",
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) This is more of a business viability risk. You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) OMG Congraats!! 🤩",
  },
  {
    "Student Name": "Miqayel Nazaryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) This is more of a business viability risk. You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) I am silent  😇(Shushanik)",
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ8) This is more of a business viability risk. You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Will have a movie night tonight 🍿",
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) Intereseting ^^",
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 75,
    "Feedback And Comments":
      "Q5) What about the second part of the question? How would you try to get rid of that muda?\nQ7) If a company is a startup it doesn't mean it does not have money to have an indoor team. The issue here is more about the mindset. Enterprises are more into execution stabilisation, Startups are more into inovating and bringing new value prepositions. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Great!",
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) OMG! How can I see that?  🤩 (Shushanik)",
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Interesting 🤩",
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) dzeec, vonc? 🤩 (Shushanik)",
  },
  {
    "Student Name": "Christine Sargsyan",
    "Grade (/100)": 85,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ5) What about the second part of the question? How would you try to get rid of that muda?\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Hmm, I think you are hard working and cute! 🤩 (Shushanik)",
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Hah, that's a common problem :( Glad to see that you have a power to help your teammates!",
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Great, where would you suggest me to hike?",
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Interesting 🤩",
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally. \nQ10) Interesting 🤩",
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) Haha, I am in love with Gyumri",
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      'Q1) "The process involves a lot of risks as you are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! 🤩',
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10)Lavn er :DDD",
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Good luck with new CV! ❤️",
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) Interesting! ☺️",
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) Want to see that pics 🌅",
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 75,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ6) I guess assuming that one model will not work for one team because they are not experienced in it is fair but not quite appropriate argument for this question. You could talk about quality, change management or release management\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) OMG, what is it? 🤩",
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Interesting!",
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      'Q1) "The process involves a lot of risks as you are not quite aware of the potential issues you will have during the development process." In this case it will be more profitable to use the spiral model as it shows you how to dill with risk. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) The pics are just awesome 🤩',
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ10) Interesting 🚙 😂",
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Yes it is really beautiful 😍",
  },
  {
    "Student Name": "Tatev Tumanyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! Yes being humble is your second name 😂",
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) That's awesome Hrach jan! (Read like in kisabac lusamutner). I see that you work very hard!",
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ5) What about the second part of the question? How would you try to get rid of that muda?\nQ10) Being responsible is awesome, make sure that the people around you value you, because you deserve it ☺️",
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! Good luck!",
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) You are defenetly going to be an awesome PM :D",
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) You are a brave and awesome woman! 😍Good luck with PM career and being a great mom!",
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) The right answer here is using Agile, as here we are talking about team freedom and uncerainty.\nQ10) OMG! How can I taste that?  🤩 (Shushanik)",
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) You are right, but what do we call that delivery mindset? ☺️\nQ5) Great, what about the second part of the question? How would you try to get rid of that muda? \nQ10)Awesome! Will wait for the analytics of the feature! 😍",
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 78,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ5) What about the second part of the question? How would you try to get rid of that muda?\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10)You are a brave and awesome woman! 😍Good luck with PM career and being a great mom!",
  },
  {
    "Student Name": "Davit Asichyan",
    "Grade (/100)": 65,
    "Feedback And Comments":
      'Q1) "The process involves a lot of risks as you are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ2) As we have a big risk component, we have better use Code and Fix\nQ5) What about the second part of the question? How would you try to get rid of that muda?\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.',
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 85,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nPleaaaaaase be short in your answers ❤️",
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! 🤩",
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ10) I like the doodle 😍",
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 70,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) It's Shushanik from CoinStats. I am not sure that I can be your brother :DDDD",
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome!",
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      'Q3) The key phrase here is "Now the product development process became completely predictable for your team. However, the stakeholder management process is still an issue and you need to make sure all the requirements have been collected and analyzed before the implementation phase". This shows that the process flow has to be watefall as you have to know the requrements then analyse and after that start implementation. The sequence matters here a lot as you have to do everything step by step also there are no risks.\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ10) Great!',
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 70,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! 🤩",
  },
  {
    "Student Name": "Lilit Petrosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ9) OMG! That's fantastic! 😍",
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) OMG! I am afraid to meet you. Please don't read my mind (Shushanik) 👀😂",
  },
  {
    "Student Name": "Levon Ghevondyan",
    "Grade (/100)": 50,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ6, 7,8,9 are missing \nQ10) Awesome! 🤩",
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! 🤩",
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) I want to try the caaake 🤩",
  },
  {
    "Student Name": "Meri Khachatryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Send me a video pleaase 🤩 (Shushanik)",
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) That's just awesome!",
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ10) Interesting 🤩",
  },
  {
    "Student Name": "Gor Alexanian",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) That's great. I will share this with the training team. (Shushanik )",
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ9) You need to talk here about the product team. You could have mentioned the PM, Designer, Developer etc: people who come together to discuss the the problems users face and upcoming solutions. \nQ10) Congrats 🤩",
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      'Q1) "The process involves a lot of risks as you are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ10) Thank you too!',
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) I guess your life is very interesting :D 🍿",
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) OMG :DDDDDD This was the funniest story I read during checking the homework.\nBy the way, thank you for your short answers!",
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 99,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! 🤩",
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) OMG I want to try it (Shushanik)",
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q2) Go with code and fix ^__^\nQ10) Name me your favorite movie 🤩",
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 100,
    "Feedback And Comments": "Q10) Yes, you do!",
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ10) The link was not working 😅",
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) That's sad 😭",
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ10) We love you too 🤩",
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q9) You need to talk here about the product team. You could have mentioned the PM, Designer, Developer etc: people who come together to discuss the the problems users face and upcoming solutions. \nQ10) OMG :DDDDDD What did you do next?",
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) OMG, send me recording 🤩",
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q5) What about the second part of the question? How would you try to get rid of that muda?\nQ10) Congrats! Smart girl jan 😍",
  },
  {
    "Student Name": "Murad Vardzelyan",
    "Grade (/100)": 78,
    "Feedback And Comments":
      "Q4) Agile 😉\nQ5) What about the second part of the question? How would you try to get rid of that muda?\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Great 🤩",
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ10) Great 🤩",
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": 70,
    "Feedback And Comments":
      'Q3) The key phrase here is "Now the product development process became completely predictable for your team. However, the stakeholder management process is still an issue and you need to make sure all the requirements have been collected and analyzed before the implementation phase". This shows that the process flow has to be watefall as you have to know the requrements then analyse and after that start implementation. The sequence matters here a lot as you have to do everything step by step also there are no risks.\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ3) The key phrase here is "Now the product development process became completely predictable for your team. However, the stakeholder management process is still an issue and you need to make sure all the requirements have been collected and analyzed before the implementation phase". This shows that the process flow has to be watefall as you have to know the requrements then analyse and after that start implementation. The sequence matters here a lot as you have to do everything step by step also there are no risks.\nQ10) Thank you 😍',
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ9) You need to talk here about the product team. You could have mentioned the PM, Designer, Developer etc: people who come together to discuss the the problems users face and upcoming solutions.",
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ10) Awesome! 😍",
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) You are a brave and awesome woman! 😍Good luck with PM career and being a great mom!",
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ8) What about testing process? \nQ10) Awesome! How did you get the scholarship? 🤩",
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Great job!",
  },
  {
    "Student Name": "Anzhela Gasparyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) Just accept yourself the way you are and try to see the benefits of it ^__^",
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q5) What about the second part of the question? How would you try to get rid of that muda?\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) That's awesome! 🤩",
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      "Q10) More then enough! 😂Happy to hear that you are safe and sound! 😂",
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 100,
    "Feedback And Comments":
      'Please write in English or in Armenian, but not Armenian with English keyboard ☺️\nQ10) What do you mean "not to be too cute?" ☺️',
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 97,
    "Feedback And Comments":
      "Q7) Every company can have a shortage of resources. The issue here is more about the mindset. Enterprises are more into execution stabilisation, Startups are more into inovating and bringing new value prepositions. \nQ10) I am so proud of you 🤩 (Shushanik)",
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) What about testing process? \nQ10) I want to see it 😍",
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) OMG So interesting 😄",
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 78,
    "Feedback And Comments":
      'Q1) "The process involves a lot of risks as you are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ4) Agile in general would work awesome here\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) You are awesome! Keep going 🤩',
  },
  {
    "Student Name": "Christine Petrosyan",
    "Grade (/100)": 70,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ6) The answer is not complete \nQ10) Sprechen sie Deutsch?",
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10)Awesome!",
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 70,
    "Feedback And Comments":
      'Q1) "You are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ2) As we have a big research component, we have better use Code and Fix\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) You are awesome ☺️',
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 50,
    "Feedback And Comments":
      "Q3, Q4, Q6, Q8, Q9 are missing\nQ10) The most intersting story 🤩",
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) The answer is not complete\nQ10) You have done an awesome job!",
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! 🤩",
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      'Q1) "You are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with\nQ4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ10) Wow! send me some pics please 🤩',
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q6) Answer is missing \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome!",
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Agile in general can help here \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Okay, see you during office hours some day ☺️",
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Sounds awesome! 🤩",
  },
  {
    "Student Name": "Neli Vardanyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! I want to learn that too 🤩",
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Oh, I want to see them (Shushanik)",
  },
  {
    "Student Name": "Haykuhi Hovhannisyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ10) You are awesome! 🤩",
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 60,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8 and Q9 have no answers\nQ10) Brave and Awesome! 🤩",
  },
  {
    "Student Name": "Amalya Fljyan",
    "Grade (/100)": 95,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ10)Great ^^",
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Sure, Agile ^^\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ9) You need to talk here about the product team. You could have mentioned the PM, Designer, Developer etc: people who come together to discuss the the problems users face and upcoming solutions.",
  },
  {
    "Student Name": "Meri Manukyan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Design thinking is a discovery mindset, rather than a product development lifecycle model. \nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Haha, I feel you pain",
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 60,
    "Feedback And Comments":
      'Q1) "The process involves a lot of risks as you are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ7) The answer is not quite clear\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ9) You need to talk here about the product team. You could have mentioned the PM, Designer, Developer etc: people who come together to discuss the the problems users face and upcoming solutions. \nQ10) OMG, I am a big fan too. Have you seen the latest episodes of Attack on Titan? (Shushanik)',
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Sounds great 🤩",
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q5) What about the second part of the question? How would you try to get rid of that muda?\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Thank you too 🤩",
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 70,
    "Feedback And Comments":
      "Q2) As we have a big risk component, we have better use Code and Fix\nQ4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Happy to hear this 🤩",
  },
  {
    "Student Name": "Lucy Shahnazaran",
    "Grade (/100)": 80,
    "Feedback And Comments":
      "Q4) Do I get it right that you will use Agile?\nQ9 The answer was not complete\nQ10) Send me an invitation when you do 🤩",
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 89,
    "Feedback And Comments":
      "Q4) More Agile ^^\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Send me the pic please 🤩",
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 98,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) Awesome! ☺️",
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 10,
    "Feedback And Comments": "Only the first question is answered",
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": 89,
    "Feedback And Comments":
      "Q4) As the process gives you freadom and the solution is needed ASAP it is better to have an Agile mindset when compiliting this task.\nQ8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) I was the youngest in our group as well^^ that's awesome (Shushanik)",
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 90,
    "Feedback And Comments":
      "Q8) You have to work closely with the GLI certification company in order to first of all understand the concrete requirements, try to create an early prototype for that RNG algorithm in order to verify that with GLI. Once you pass the algorithmic requirements, you can built all the functionalities on top of it. Simply, learn + build + test internally + test externally.\nQ10) I believe you 😍 (Shushanik)",
  },
  {
    "Student Name": "Lilith Dalaqyan",
    "Grade (/100)": 100,
    "Feedback And Comments": "",
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": 80,
    "Feedback And Comments":
      'Q1) "You are not quite aware of the potential issues you will have during the development process." This means that the you need to use a Spiral model as we are dealing with a huge risk \nQ3)The key phrase here is "Now the product development process became completely predictable for your team. However, the stakeholder management process is still an issue and you need to make sure all the requirements have been collected and analyzed before the implementation phase". This shows that the process flow has to be watefall as you have to know the requrements then analyse and after that start implementation. The sequence matters here a lot as you have to do everything step by step also there are no risks.',
  },
];

const hwk4 = [
  {
    "Student Name": "Nelly Vardanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 86,
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 96,
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 99,
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 59,
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 95,
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 95,
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": 97,
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 89,
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "meri Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vardan Hayrapetyan",
    "Grade (/100)": 82,
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Haykanush Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristine Petrosyan",
    "Grade (/100)": 94,
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    "Grade (/100)": 61,
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 97,
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 89,
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Murad Vardzelyan",
    "Grade (/100)": 68,
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": 90,
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Lilit Dallakyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristine Sargsyan",
    "Grade (/100)": 71,
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Amalya Flijyan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": 86,
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 99,
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 90,
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Tatevik Tumanyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mikael Nazaryan",
    "Grade (/100)": 97,
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 98,
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Meri Manukyan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 51,
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Lilith Petrosyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 93,
  },
];

const hwk5 = [
  {
    "Student Name": "Nelly Vardanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Muradyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ani Davtyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "meri Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Gayane Papinyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vardan Hayrapetyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Haykanush Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristine Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Astghik Malkhasyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Davit Asichyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Aram Andreasyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristina Hovsepyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Lilit Dallakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristine Sargsyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Luse Avetisyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tatevik Tumanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mikael Nazaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meri Manukyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Amiryan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilith Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anahit Vardanyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 100,
  },
];

const hwk6 = [
  {
    "Student Name": "Nelly Vardanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Muradyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ani Davtyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "meri Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Gayane Papinyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Vardan Hayrapetyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Haykanush Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristine Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Astghik Malkhasyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Aram Andreasyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristina Hovsepyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Lilit Dallakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Kristine Sargsyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Amalya Flijyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Luse Avetisyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tatevik Tumanyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mikael Nazaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilit Amiryan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": 59,
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Lilith Petrosyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 100,
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Anahit Vardanyan",
    "Grade (/100)": 0,
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 100,
  },
];

const hwk7 = [
  {
    "Student Name": "Nelly Vardanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Muradyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Angela Gasparyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ani Davtyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "meri Khachatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Gayane Papinyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Vardan Hayrapetyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Haykanush Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Kristine Petrosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Levon Ghevondyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Astghik Malkhasyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Davit Asichyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Murad Vardzelyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Aram Andreasyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Kristina Hovsepyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Dallakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Kristine Sargsyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Amalya Flijyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Luse Avetisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tatevik Tumanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Mikael Nazaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Meri Manukyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Amiryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilith Petrosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anahit Vardanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 85,
  },
];

const midterm = [
  {
    "Student Name": "Vrezh Oganisyan",
    Grade: 77,
    "Grade (/100)": "62%",
  },
  {
    "Student Name": "Lilith Dallakyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": "59%",
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": "80%",
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": "84%",
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": "71%",
  },
  {
    "Student Name": "Haykuhi Hovhannisyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Gor Alexanian",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Meri Khachatryan",
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Mikayel Nazaryan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": "61%",
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": "76%",
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Tatev Tumanyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": "77%",
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": "66%",
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": "68%",
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": "80%",
  },
  {
    "Student Name": "Christine Sargsyan",
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "murad vardzelyan",
    "Grade (/100)": "48%",
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": "67%",
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": "56%",
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": "87%",
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": "64%",
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Tatevik Sevoyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": "87%",
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": "97%",
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": "66%",
  },
  {
    "Student Name": "Amalya Fljyan",
    "Grade (/100)": "50%",
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Lilit Sahkyan",
    "Grade (/100)": "78%",
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": "95%",
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": "97%",
  },
  {
    "Student Name": "Meri Manukyan",
    "Grade (/100)": "75%",
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": "88%",
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Amalya Fljyan",
    "Grade (/100)": "97%",
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": "31%",
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": "72%",
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": "81%",
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Vardan Hayrapetyan",
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": "73%",
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": "87%",
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": "61%",
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": "61%",
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": "95%",
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": "73%",
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": "88%",
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": "87%",
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": "88%",
  },
  {
    "Student Name": "Davit Asichyan",
    "Grade (/100)": "69%",
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": "55%",
  },
  {
    "Student Name": "Lilit Petrosyan",
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": "97%",
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": "79%",
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": "58%",
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": "88%",
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": "87%",
  },
  {
    "Student Name": "Levon Ghevondyan",
    "Grade (/100)": "82%",
  },
  {
    "Student Name": "Neli Vardanyan",
    "Grade (/100)": "77%",
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Grade (/100)": "70%",
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": "99%",
  },
  {
    "Student Name": "Christine Petrosyan",
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": "87%",
  },
  {
    "Student Name": "Ina Lalazaryan",
    "Grade (/100)": "5%",
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    "Grade (/100)": "62%",
  },
];

midterm.name = "Midterm";

const exam = [
  {
    "Student Name": "Vrezh Oganisyan",
    Grade: 77,
    "Grade (/100)": "62%",
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    Grade: 77,
    "Grade (/100)": "62%",
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    Grade: 117,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Armine Hakobyan",
    Grade: 100,
    "Grade (/100)": "80%",
  },
  {
    "Student Name": "Hayk Madoyan",
    Grade: 98,
    "Grade (/100)": "78%",
  },
  {
    "Student Name": "Mariam Hayruni",
    Grade: 115,
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Samvel Harutyunyan",
    Grade: 105,
    "Grade (/100)": "84%",
  },
  {
    "Student Name": "Diana Danielyan",
    Grade: 59,
    "Grade (/100)": "47%",
  },
  {
    "Student Name": "Marianna Ohanyan",
    Grade: 98,
    "Grade (/100)": "78%",
  },
  {
    "Student Name": "Samvel Ashotyan",
    Grade: 111,
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Arman Petrosyan",
    Grade: 67,
    "Grade (/100)": "54%",
  },
  {
    "Student Name": "Anna Harutyunyan",
    Grade: 100,
    "Grade (/100)": "80%",
  },
  {
    "Student Name": "Liana Davoyan",
    Grade: 118,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Vicky Masrie",
    Grade: 90,
    "Grade (/100)": "72%",
  },
  {
    "Student Name": "Anna Martirosyan",
    Grade: 120,
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Anush Khojayan",
    Grade: 117,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Davit Asichyan",
    Grade: 50,
    "Grade (/100)": "40%",
  },
  {
    "Student Name": "Haykuhi Hovhannisyan",
    Grade: 105,
    "Grade (/100)": "84%",
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    Grade: 104,
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Zhanna Lalayan",
    Grade: 106,
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Tatevik Manukyan",
    Grade: 77,
    "Grade (/100)": "62%",
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    Grade: 117,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Mariam Martirosyan",
    Grade: 117,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Նարե Հարությունյան",
    Grade: 59,
    "Grade (/100)": "47%",
  },
  {
    "Student Name": "Gor Aleksanyan",
    Grade: 112,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Astghik Nalchajyan",
    Grade: 114,
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Anahit Ananyan",
    Grade: 104,
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Rita Vardanyan",
    Grade: 117,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Hovik Tamazyan",
    Grade: 106,
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Irina Oganesova",
    Grade: 55,
    "Grade (/100)": "44%",
  },
  {
    "Student Name": "Alik Martirosyan",
    Grade: 107,
    "Grade (/100)": "86%",
  },
  {
    "Student Name": "Shushan Margaryan",
    Grade: 92,
    "Grade (/100)": "74%",
  },
  {
    "Student Name": "Karapet Hovsepyan",
    Grade: 94,
    "Grade (/100)": "75%",
  },
  {
    "Student Name": "Ella Asatryan",
    Grade: 111,
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Tatev Sevoyan",
    Grade: 106,
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Anush Abrahamyan",
    Grade: 99,
    "Grade (/100)": "79%",
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    Grade: 116,
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Suren Khachatryan",
    Grade: 104,
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    Grade: 98,
    "Grade (/100)": "78%",
  },
  {
    "Student Name": "Knarik Martirosyan",
    Grade: 66,
    "Grade (/100)": "53%",
  },
  {
    "Student Name": "Lilit Poghosyan",
    Grade: 107,
    "Grade (/100)": "86%",
  },
  {
    "Student Name": "Roza Tovmasyan",
    Grade: 118,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Lusine Harutyunyan",
    Grade: 93,
    "Grade (/100)": "74%",
  },
  {
    "Student Name": "Marine Makaryan",
    Grade: 105,
    "Grade (/100)": "84%",
  },
  {
    "Student Name": "Anahit Muradyan",
    Grade: 116,
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    Grade: 107,
    "Grade (/100)": "86%",
  },
  {
    "Student Name": "Satenik Movsesyan",
    Grade: 115,
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Anush Sahakyan",
    Grade: 67,
    "Grade (/100)": "54%",
  },
  {
    "Student Name": "Hasmik Meloyan",
    Grade: 114,
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Armine Samvelyan",
    Grade: 76,
    "Grade (/100)": "61%",
  },
  {
    "Student Name": "Lusine Shakhoyan",
    Grade: 123,
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    Grade: 114,
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    Grade: 118,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Meri Torosyan",
    Grade: 125,
    "Grade (/100)": "100%",
  },
  {
    "Student Name": "Neli Vardanyan",
    Grade: 120,
    "Grade (/100)": "96%",
  },
  {
    "Student Name": "Aram Zoryan",
    Grade: 90,
    "Grade (/100)": "72%",
  },
  {
    "Student Name": "Anna Simonyan",
    Grade: 111,
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Narine Ghazaryan",
    Grade: 99,
    "Grade (/100)": "79%",
  },
  {
    "Student Name": "Davit Arakelyan",
    Grade: 114,
    "Grade (/100)": "91%",
  },
  {
    "Student Name": "Nazeli Badalyan",
    Grade: 104,
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Lilit Saribekyan",
    Grade: 104,
    "Grade (/100)": "83%",
  },
  {
    "Student Name": "Tamara Harutyunyan",
    Grade: 111,
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Flora Matsakyan",
    Grade: 60,
    "Grade (/100)": "48%",
  },
  {
    "Student Name": "Emma Sukiasyan",
    Grade: 112,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Sergey Safaryan",
    Grade: 108,
    "Grade (/100)": "86%",
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    Grade: 89,
    "Grade (/100)": "71%",
  },
  {
    "Student Name": "Gayane Papinyan",
    Grade: 72,
    "Grade (/100)": "58%",
  },
  {
    "Student Name": "Heghine Balyan",
    Grade: 100,
    "Grade (/100)": "80%",
  },
  {
    "Student Name": "Syuzanna Amiryan",
    Grade: 80,
    "Grade (/100)": "64%",
  },
  {
    "Student Name": "Ruzanna Durunts",
    Grade: 115,
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Armen Nersesyan",
    Grade: 108,
    "Grade (/100)": "86%",
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    Grade: 113,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Christine Sargsyan",
    Grade: 92,
    "Grade (/100)": "74%",
  },
  {
    "Student Name": "Tatev Tumanyan",
    Grade: 102,
    "Grade (/100)": "82%",
  },
  {
    "Student Name": "Christine Petrosyan",
    Grade: 103,
    "Grade (/100)": "82%",
  },
  {
    "Student Name": "Tamara Sedrakyan",
    Grade: 123,
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Mari Minasyan",
    Grade: 107,
    "Grade (/100)": "86%",
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    Grade: 93,
    "Grade (/100)": "74%",
  },
  {
    "Student Name": "Tatevik Sargsyan",
    Grade: 87,
    "Grade (/100)": "70%",
  },
  {
    "Student Name": "Khachik Takiryan",
    Grade: 71,
    "Grade (/100)": "57%",
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    Grade: 106,
    "Grade (/100)": "85%",
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    Grade: 71,
    "Grade (/100)": "57%",
  },
  {
    "Student Name": "Mariam Tunyan",
    Grade: 123,
    "Grade (/100)": "98%",
  },
  {
    "Student Name": "Mushegh Galstyan",
    Grade: 64,
    "Grade (/100)": "51%",
  },
  {
    "Student Name": "Anna Khachatryan",
    Grade: 115,
    "Grade (/100)": "92%",
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    Grade: 65,
    "Grade (/100)": "52%",
  },
  {
    "Student Name": "Lena Petrosyan",
    Grade: 116,
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Lusine Poghosyan",
    Grade: 105,
    "Grade (/100)": "84%",
  },
  {
    "Student Name": "Vardan Jangozyan",
    Grade: 110,
    "Grade (/100)": "88%",
  },
  {
    "Student Name": "Mikayel Nazaryan",
    Grade: 69,
    "Grade (/100)": "55%",
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    Grade: 68,
    "Grade (/100)": "54%",
  },
  {
    "Student Name": "Meri Khachatryan",
    Grade: 102,
    "Grade (/100)": "82%",
  },
  {
    "Student Name": "Meline Hovhannisyan",
    Grade: 96,
    "Grade (/100)": "77%",
  },
  {
    "Student Name": "Lilit Aghajanyan",
    Grade: 88,
    "Grade (/100)": "70%",
  },
  {
    "Student Name": "Ara Avagyan",
    Grade: 93,
    "Grade (/100)": "74%",
  },
  {
    "Student Name": "Anna Galstyan",
    Grade: 95,
    "Grade (/100)": "76%",
  },
  {
    "Student Name": "Araksya Yeprikyan",
    Grade: 113,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Amalya Fljyan",
    Grade: 101,
    "Grade (/100)": "81%",
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    Grade: 39,
    "Grade (/100)": "31%",
  },
  {
    "Student Name": "Sona Hovsepyan",
    Grade: 117,
    "Grade (/100)": "94%",
  },
  {
    "Student Name": "Lilith Dallakyan",
    Grade: 116,
    "Grade (/100)": "93%",
  },
  {
    "Student Name": "Gayane Kharatyan",
    Grade: 12,
    "Grade (/100)": "10%",
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    Grade: 97,
    "Grade (/100)": "78%",
  },
  {
    "Student Name": "Parandzem Voskanyan",
    Grade: 82,
    "Grade (/100)": "66%",
  },
  {
    "Student Name": "Ani Makaryan",
    Grade: 83,
    "Grade (/100)": "66%",
  },
  {
    "Student Name": "Hayk Grigoryan",
    Grade: 103,
    "Grade (/100)": "82%",
  },
  {
    "Student Name": "Arusyak Zakharyan",
    Grade: 111,
    "Grade (/100)": "89%",
  },
  {
    "Student Name": "Մերի Մանուկյան",
    Grade: 70,
    "Grade (/100)": "56%",
  },
  {
    "Student Name": "Dianna Galstyan",
    Grade: 88,
    "Grade (/100)": "70%",
  },
  {
    "Student Name": "Levon Ghevondyan",
    Grade: 89,
    "Grade (/100)": "71%",
  },
  {
    "Student Name": "Arpi Gabrielyan",
    Grade: 113,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Liana Sahakyan",
    Grade: 113,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Kristina Hovsepyan",
    Grade: 0,
    "Grade (/100)": "0%",
  },
  {
    "Student Name": "Tatevik Khachatryan",
    Grade: 88,
    "Grade (/100)": "70%",
  },
  {
    "Student Name": "Haykanush Chobanyan",
    Grade: 83,
    "Grade (/100)": "66%",
  },
  {
    "Student Name": "Lilit Sahakyan",
    Grade: 65,
    "Grade (/100)": "52%",
  },
  {
    "Student Name": "Svetlana Asatryan",
    Grade: 105,
    "Grade (/100)": "84%",
  },
  {
    "Student Name": "Ani Saghatelyan",
    Grade: 112,
    "Grade (/100)": "90%",
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    Grade: 78,
    "Grade (/100)": "62%",
  },
  {
    "Student Name": "Gayane Kharatyan",
    Grade: 60,
    "Grade (/100)": "48%",
  },
];

exam.name = "Final Exam";

const finalScore = [
  {
    "Student Name": "Ruzanio",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Vrezh Oganisyan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Nelly Vardanyan",
    "Grade (/100)": 83,
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Grade (/100)": 70,
  },
  {
    "Student Name": "Rafayel Khachatryan",
    "Grade (/100)": 7,
  },
  {
    "Student Name": "Flora Matsakyan",
    "Grade (/100)": 38,
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Armine Samvelyan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Samvel Hovhannisyan",
    "Grade (/100)": 30,
  },
  {
    "Student Name": "Armine Hakobyan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Hovhannes Mkrtchyan",
    "Grade (/100)": 19,
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Grade (/100)": 63,
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Grade (/100)": 79,
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Grade (/100)": 86,
  },
  {
    "Student Name": "Alik Martirosyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Svetlana Asatryan",
    "Grade (/100)": 56,
  },
  {
    "Student Name": "Armen Nersesyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Anna Muradyan",
    "Grade (/100)": 4,
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Lilit Sahakyan",
    "Grade (/100)": 46,
  },
  {
    "Student Name": "Suren Khachatryan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Angela Gasparyan",
    "Grade (/100)": 10,
  },
  {
    "Student Name": "Ani Davtyan",
    "Grade (/100)": 6,
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Vicky Masrie",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Lusine Harutyunyan",
    "Grade (/100)": 60,
  },
  {
    "Student Name": "Anna Simonyan",
    "Grade (/100)": 90,
  },
  {
    "Student Name": "Rita Vardanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Aram Zoryan",
    "Grade (/100)": 76,
  },
  {
    "Student Name": "Samvel Ashotyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Liana Davoyan",
    "Grade (/100)": 94,
  },
  {
    "Student Name": "meri Khachatryan",
    "Grade (/100)": 81,
  },
  {
    "Student Name": "Gayane Papinyan",
    "Grade (/100)": 46,
  },
  {
    "Student Name": "Vardan Hayrapetyan",
    "Grade (/100)": 22,
  },
  {
    "Student Name": "Ani Makaryan",
    "Grade (/100)": 75,
  },
  {
    "Student Name": "Syuzanna Mkrtchyan",
    "Grade (/100)": 55,
  },
  {
    "Student Name": "Haykanush Hovhannisyan",
    "Grade (/100)": 69,
  },
  {
    "Student Name": "Lena Petrosyan",
    "Grade (/100)": 94,
  },
  {
    "Student Name": "Heghine Balyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Grade (/100)": 95,
  },
  {
    "Student Name": "Christine Petrosyan",
    "Grade (/100)": 82,
  },
  {
    "Student Name": "Levon Ghevondyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Zhanna Lalayan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Parandzem Hayrikyan",
    "Grade (/100)": 46,
  },
  {
    "Student Name": "Nazeli Badalyan",
    "Grade (/100)": 62,
  },
  {
    "Student Name": "Tatevik Sargsyan",
    "Grade (/100)": 56,
  },
  {
    "Student Name": "Mariam Tunyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Astghik Malkhasyan",
    "Grade (/100)": 6,
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Grade (/100)": 79,
  },
  {
    "Student Name": "Shushan Margaryan",
    "Grade (/100)": 76,
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Davit Asichyan",
    "Grade (/100)": 40,
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Anna Khachatryan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Murad Vardzelyan",
    "Grade (/100)": 44,
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Grade (/100)": 79,
  },
  {
    "Student Name": "Aram Andreasyan",
    "Grade (/100)": 22,
  },
  {
    "Student Name": "Tamara Sedrakyan",
    "Grade (/100)": 68,
  },
  {
    "Student Name": "Meri Torosyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Grade (/100)": 75,
  },
  {
    "Student Name": "Kristina Hovsepyan",
    "Grade (/100)": 2,
  },
  {
    "Student Name": "Lilit Dallakyan",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Khachik Takiryan",
    "Grade (/100)": 70,
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Mariam Hayruni",
    "Grade (/100)": 65,
  },
  {
    "Student Name": "Christine Sargsyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Amalya Fljyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Marine Makaryan",
    "Grade (/100)": 59,
  },
  {
    "Student Name": "Anna Martirosyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Ella Asatryan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Luse Avetisyan",
    "Grade (/100)": 2,
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Grade (/100)": 82,
  },
  {
    "Student Name": "Dianna Galstyan",
    "Grade (/100)": 75,
  },
  {
    "Student Name": "Sergey Safaryan",
    "Grade (/100)": 89,
  },
  {
    "Student Name": "Liana Sahakyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Grade (/100)": 74,
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Davit Arakelyan",
    "Grade (/100)": 92,
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Grade (/100)": 82,
  },
  {
    "Student Name": "Lilit Aghajanyan/Saribekyan",
    "Grade (/100)": 77,
  },
  {
    "Student Name": "Tatevik Tumanyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Grade (/100)": 74,
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Mikael Nazaryan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Arman Petrosyan",
    "Grade (/100)": 72,
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Anush Sahakyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Hayk Madoyan",
    "Grade (/100)": 60,
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Grade (/100)": 59,
  },
  {
    "Student Name": "Ara Avagyan",
    "Grade (/100)": 86,
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Grade (/100)": 51,
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Grade (/100)": 68,
  },
  {
    "Student Name": "Anush Khojayan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Meri Manukyan",
    "Grade (/100)": 46,
  },
  {
    "Student Name": "Lilit Hovnikyan",
    "Grade (/100)": 25,
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Anahit Ananyan",
    "Grade (/100)": 79,
  },
  {
    "Student Name": "Lilit Amiryan",
    "Grade (/100)": 2,
  },
  {
    "Student Name": "Emma Sukiasyan",
    "Grade (/100)": 62,
  },
  {
    "Student Name": "Mari Minasyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Irina Oganesova",
    "Grade (/100)": 62,
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Diana Danielyan",
    "Grade (/100)": 57,
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Anahit Muradyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Anna Galstyan",
    "Grade (/100)": 79,
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Grade (/100)": 91,
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Grade (/100)": 61,
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Grade (/100)": 88,
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Grade (/100)": 89,
  },
  {
    "Student Name": "Lilith Petrosyan",
    "Grade (/100)": 31,
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Grade (/100)": 79,
  },
  {
    "Student Name": "Tigran Geghamyan",
    "Grade (/100)": 26,
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Grade (/100)": 85,
  },
  {
    "Student Name": "Anahit Vardanyan",
    "Grade (/100)": 2,
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Ina Lalazaryan",
    "Grade (/100)": 13,
  },
  {
    "Student Name": "Armen Sargsyan",
    "Grade (/100)": 13,
  },
];

finalScore.name = "Final Score";

const finalProject = [
  {
    "Student Name": "Vrezh Oganisyan",
    "Feedback And Comments":
      "JustQR / Awesome presentation guys. Geat job. There are a couple of comments. (A) You have a good go-to-market strategy for the Armenian market, but how ca you scale the business? (B) What would be next steps in the product when it \ncomes to implement next features? - What's the great vision? (C) There are a lot of risks associated from bigger stakeholders such as banks, global application and software producers.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Hovhannes Hovhannisyan",
    "Feedback And Comments":
      "Interview Simulation / Awesome presentation. Great job guys. There are a couple of comments. (A) The beginning of the story was too long. You could definitely compress it. (B) The pricing model was not clear enough for the Armenian market. It seems\nlike strong professionals would want to charge more than that. (C) Seems like you have done a lot of work on the interviewee side but not sufficient work on the interviewer side. There is definitely a big room for improvement. (D)\nTry to recalculate your investment size. You definititely need more than that for the whole team to work full time and hire additional team members for 18 months.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Hasmik Meloyan",
    "Feedback And Comments":
      "SmartEducation / Good presentation guys. The problem is actually very big and the solution seems very promising. Great design, by the way. There are a couple of comments. (A) The team should work more on its business model. Working with\ngovernments can get pretty harsh and there could be better monetization models, such as parents. (B) It was unclear how the team will guarantee a personalized and high quality content. That's probably the biggest risk inside\nyour product. (C) The budget is too small. You will definitely need more for 18-months period.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Mariam Martirosyan",
    "Feedback And Comments":
      "News Insight / The presentation was good guys. The problem is really BIG. There are a couple of comments. (A) The team still needs to put a lot of effort on figuring out the winning solution. (B) There are a lot of technical risks associated with the\nproduct and there is definitely a need for strong engineering expertise. (C) We didn't sense a strong go-to-market strategy. How would the product go viral? (D) You didn't provide the feeling that with your business model you can \nhave a scalable and repeatible growth. But if you guys build a great solution - this is a BILLION dollar startup.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Ruzanna Durunts",
    "Feedback And Comments":
      "UteLove / Awesome presentation guys. You nailed it. Here are a couple of comments. (A) How would you make those rating less subjective? (B) If you want to become a billion dollar idea, unfortunately you must forget about Armenia and \nenter the US market, or somewhere else. (C) The go-to-market strategy was not really well shaped. The naming is very cool, by the way. Keep rocking guys!",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Mari Minasyan",
    "Feedback And Comments":
      "BerTar / Good presetation guys. The mission is good, the prototype is great. There are a couple of comments. (A) The team needs to work a bit more on its marketing strategy. The GTM and business model can be improved. (B) THe hardest\nthing will be to grow the network in the beginning, if you can manage that part, the rest will be much easier.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Hrach Mkhitaryan",
    "Feedback And Comments":
      "Interview Simulation / Awesome presentation. Great job guys. There are a couple of comments. (A) The beginning of the story was too long. You could definitely compress it. (B) The pricing model was not clear enough for the Armenian market. It seems\nlike strong professionals would want to charge more than that. (C) Seems like you have done a lot of work on the interviewee side but not sufficient work on the interviewer side. There is definitely a big room for improvement. (D)\nTry to recalculate your investment size. You definititely need more than that for the whole team to work full time and hire additional team members for 18 months.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Nare Mnatsakanyan",
    "Feedback And Comments":
      "Universal Digital Banking / Good presentation guys. There is definitely a market there. There are a couple of commets. (A) As you already know there are many risks associated with loan payment. You need to make a bit more persuasive model regarding \nhow would you tackle all those risks? (C) How risk-averse are the investors. How can you design a good go-to-market strategy for bringing those people to the product? (C) This product requires a lot of connections at the beginning. \nHow would you build the right network for it? (D) Would people still prefer this product with associated risks over going to a bank with good brand awareness? (E) You definitely need to reanalize the resources you need to start the\nproduct.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Syuzanna Amiryan",
    "Feedback And Comments":
      "Study / Good presentation guys. You are starting from a good niche. There are a couple of comments. (A) The market size seems too small. We do understand Armenian market is just for piloting purposes but you should DREAM BIG.\n(B) There was no clear go-to-market strategy for getting into outside Armenia. That's really important for building a scalable business model. (C) Also, you need to work a little bit more on the business model of the product. You \ncan try to think of additional monetization channels as well. (D) You can definitely make better financial projects.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Arpi Gabrielyan",
    "Feedback And Comments":
      "DocTech / Good presentation guys. Awesome job. There is definitely a need in the market. Here are a couple of comments. (A) The market might not be very ready right now but it's getting there. (B) The go-to-market strategy was a bit\nunclear. (C) The financial breakeven seems unreachable with this business model. You can definitely improve it.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Liana Sahakyan",
    "Feedback And Comments":
      "EduMate / Awesome presentation guys. Great job! There are a couple of things you can do to improve your product work. (A) It was not clear enough how you motivate more mentor engagement. Maybe, a better monetization could help. \n(B) There are also some risks involved with your tests - how to make sure they are not subjective and well-aligned to the customers' real needs? This is a solvable problem but you need to work a bit more on that. (C) The business\nmodel and the payment flow inside the product were a little bit unclear at the beginning. P.S. We LOVED your confidence.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Rita Vardanyan",
    "Feedback And Comments":
      "MoneyWise / Good presentation guys. There are couple of comments. (A) It seems like the team needs to work a bit more to find more effective service approaches. (B) It will not probably replace our accounts, but it can definitely become our\nassistant. There is a big potential if you success in managerial tool development with business insights.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Marianna Ohanyan",
    "Feedback And Comments":
      "Interview Simulation / Awesome presentation. Great job guys. There are a couple of comments. (A) The beginning of the story was too long. You could definitely compress it. (B) The pricing model was not clear enough for the Armenian market. It seems\nlike strong professionals would want to charge more than that. (C) Seems like you have done a lot of work on the interviewee side but not sufficient work on the interviewer side. There is definitely a big room for improvement. (D)\nTry to recalculate your investment size. You definititely need more than that for the whole team to work full time and hire additional team members for 18 months.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Lusine Poghosyan",
    "Feedback And Comments":
      "ArmCraft / Good presentation guys. There are a couple of comments. (A) The marker seems too small. Please try to DREAM BIG. There could be a lot of potential ways to scale the business. (B) It's a bit unclear what was your go-to-market\nstrategy. (C) Seems more of a non-profit project and you need to work more on your business model. (D) Overall it seems a small market, targeted Armenians only with unclear breakeven strategy, low commission. We are also\nnot sure about advanced services, the target audience might have no money to order advanced services.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Mariam Tunyan",
    "Feedback And Comments":
      "EduCatcher / Good presentation guys. However, there are a couple of things you can improve. (A) At the beginning there was some confusion regarding your problem space. You can definitely work to make it more clear. (B) The market \ndescription was unclear as well. (C) The team was confused about the product as well. Maybe you can focus more on the recommendation engine, rather than just selling books. (D) Monetization process was not well-developed.\nThere are definitely better ways you can make revenue.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Lilit Aghajanyan",
    "Feedback And Comments":
      'Simplist / Great presentation guys. We really liked it. The stortelling was just amazing. There are couple of comments. (A) You MUST sell the big vision of entering a HUGE market. (B) Never say "chgitem" during Q&A. Never! That\'s a red flag.\n(B) Make sure you sell how big your market can be. (C) There were some uncertainties regarding the financial part (cost and incomes), and the calculations seemed a bit unrealistic.',
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Vicky Masrie",
    "Feedback And Comments":
      "Budgeting Matters / Good presentation guys. There is definitely a market out there. Here are a couple of comments. (A) The challenge is that do users really want more than a standard exceel spreadsheet? (B) Also, it wasn't clear what is the team's\ngo-to-market strategy. (C) It wasn't very clear what was the product's unfair advantage over its competitors. (D) We didn't sense a clear killer feature for the product. (E) We noticed no engagement tools and initiative that are the most \nimportant features for this kind of product, where the discipline is needed.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Ella Asatryan",
    "Feedback And Comments":
      "EduCatcher / Good presentation guys. However, there are a couple of things you can improve. (A) At the beginning there was some confusion regarding your problem space. You can definitely work to make it more clear. (B) The market \ndescription was unclear as well. (C) The team was confused about the product as well. Maybe you can focus more on the recommendation engine, rather than just selling books. (D) Monetization process was not well-developed.\nThere are definitely better ways you can make revenue.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Tatevik Tumanyan",
    "Feedback And Comments":
      "Smart Lounge / Good presentation guys. There are a couple of comments. (A) It feels like you still need to do more researcha about your market. (B) There can definitely be some improvements in your business model. (C) If you want to grow\nlike crazy, you will need to get outside Armenia.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Roza Tovmasyan",
    "Feedback And Comments":
      "CareerHub / Awesome presentation guys. There is definitely big market there outside Armenia. There are a couple of comments. (A) It is still unclear what's your killer feature. (B) Where are you going to concentrate more? - The Landing page \nbuilder? The ATS? The Analytics? Where are you going to concentrate more at the beginning? (C) You should work more on the go-to-market strategy for the US market. You might brand ambassadors and advisors from that region. \n(D) Not the best competitor analysis. You can definitely do better. Awesome pitch and slide deck. Good luck!",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Hayarpi Vasilyan",
    "Feedback And Comments":
      "Budgeting Matters / Good presentation guys. There is definitely a market out there. Here are a couple of comments. (A) The challenge is that do users really want more than a standard exceel spreadsheet? (B) Also, it wasn't clear what is the team's\ngo-to-market strategy. (C) It wasn't very clear what was the product's unfair advantage over its competitors. (D) We didn't sense a clear killer feature for the product. (E) We noticed no engagement tools and initiative that are the most \nimportant features for this kind of product, where the discipline is needed.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Hayk Grigoryan",
    "Feedback And Comments":
      "EduMate / Awesome presentation guys. Great job! There are a couple of things you can do to improve your product work. (A) It was not clear enough how you motivate more mentor engagement. Maybe, a better monetization could help. \n(B) There are also some risks involved with your tests - how to make sure they are not subjective and well-aligned to the customers' real needs? This is a solvable problem but you need to work a bit more on that. (C) The business\nmodel and the payment flow inside the product were a little bit unclear at the beginning. P.S. We LOVED your confidence.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Knarik Hovhannisyan",
    "Feedback And Comments":
      "Study / Good presentation guys. You are starting from a good niche. There are a couple of comments. (A) The market size seems too small. We do understand Armenian market is just for piloting purposes but you should DREAM BIG.\n(B) There was no clear go-to-market strategy for getting into outside Armenia. That's really important for building a scalable business model. (C) Also, you need to work a little bit more on the business model of the product. You \ncan try to think of additional monetization channels as well. (D) You can definitely make better financial projects.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Amalya Flijyan",
    "Feedback And Comments":
      "Barbar / Good presentation guys. Maybe not a very business-oriented but more of a social project. There are couple of comments. (A) You guys need to work a little bit more on your business model and revenue streams. (B) Think of how\ncan you make a BIG VISION out of it. Sell big. It's normal to start from a small market, but there should be some scaling opportunities --> This is how you make a scalable and repeatable business model. (C) Think about how can \nyou increase the item offering inside the product to make better monetization out of it. (D) Work on making better market estimations - it's crucial. (E) You can definitely improve your pitch deck.",
    "Grade (/100)": 78,
  },
  {
    "Student Name": "Lusine Shakhoyan",
    "Feedback And Comments":
      'Simplist / Great presentation guys. We really liked it. The stortelling was just amazing. There are couple of comments. (A) You MUST sell the big vision of entering a HUGE market. (B) Never say "chgitem" during Q&A. Never! That\'s a red flag.\n(B) Make sure you sell how big your market can be. (C) There were some uncertainties regarding the financial part (cost and incomes), and the calculations seemed a bit unrealistic.',
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Anush Abrahamyan",
    "Feedback And Comments":
      "WithMyKid / Great presentation guys. Well done. There are a couple of comments. (A) The team should work a bit more to figure out a concrete business model. (B) Having a well-shaped go-to-market strategy is super important. (C) Work on\nthe design aspect to make it awesome.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Alik Martirosyan",
    "Feedback And Comments":
      "MoneyWise / Good presentation guys. There are couple of comments. (A) It seems like the team needs to work a bit more to find more effective service approaches. (B) It will not probably replace our accounts, but it can definitely become our\nassistant. There is a big potential if you success in managerial tool development with business insights.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Irina Oganesova",
    "Feedback And Comments":
      "PharmaGuide / Great presentation guys. Good job. There are a couple of comments. (A) Make sure to have a good go-to-market strategy for outside Armenia. Like how would you get to the Georgian market? (B) Work a little bit more on the \nmonetization aspect. It felt like you didn't put enough thought on it. (C) There are some shared concerns about the target audience - can they actually use the services. The speech and the presentation were just great.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Meri Khachatryan",
    "Feedback And Comments":
      "Evento / Good presentation guys. There are a couple of comments. (A) We didnt' quite understand the necessity of digitilizing the process of this types of event organization. The success of your product will depend mostly on the quailty \nof the organization of the events, instead of the quality of your product. (C) There is definitely some human touch expected in these types of event organizations. (D) There is a lot of heavy operational work involved with this\nbusiness.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Nelly Vardanyan",
    "Feedback And Comments":
      "TrustMe / Good presentation. There are a couple of comments. (A) It was unclear what your MVP was going to be. (B) We couldn't find a clear explanation of the killer feature of the product. (C) Armenian market is too small for a specific\npersonalizatio  just for Armenians. The market size is too small. Business-wise it would make more sense to target larger markets. (D) It was not very clear what was the business model and the global market size of the product. (E)\nIn terms of the product, there is going to be too much extra work for entering another market, such as Georgia. (E) There was no clear growth strategy for the product.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Anahit Ananyan",
    "Feedback And Comments":
      "Smart Lounge / Good presentation guys. There are a couple of comments. (A) It feels like you still need to do more researcha about your market. (B) There can definitely be some improvements in your business model. (C) If you want to grow\nlike crazy, you will need to get outside Armenia.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Knarik Martirosyan",
    "Feedback And Comments":
      "EduMate / Awesome presentation guys. Great job! There are a couple of things you can do to improve your product work. (A) It was not clear enough how you motivate more mentor engagement. Maybe, a better monetization could help. \n(B) There are also some risks involved with your tests - how to make sure they are not subjective and well-aligned to the customers' real needs? This is a solvable problem but you need to work a bit more on that. (C) The business\nmodel and the payment flow inside the product were a little bit unclear at the beginning. P.S. We LOVED your confidence.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Armen Nersesyan",
    "Feedback And Comments":
      "WithMyKid / Great presentation guys. Well done. There are a couple of comments. (A) The team should work a bit more to figure out a concrete business model. (B) Having a well-shaped go-to-market strategy is super important. (C) Work on\nthe design aspect to make it awesome.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Khachik Takiryan",
    "Feedback And Comments":
      "Keepy / Good presentation guy. A couple of comments. (A) There is definitely some work that needs to be done in terms of the business model. (B) You found a real problem. The big issue is the market size. Services like that are \nunfortunatelly very cheap in Armenia. If you make about 300AMD from each visit it will be hard to make up for your costs. Focus on finding other ways for monitization. (C) Think about a killer feature that would give an unfair\nadvantage over your competitors.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Armine Hakobyan",
    "Feedback And Comments":
      "SmartEducation / Good presentation guys. The problem is actually very big and the solution seems very promising. Great design, by the way. There are a couple of comments. (A) The team should work more on its business model. Working with\ngovernments can get pretty harsh and there could be better monetization models, such as parents. (B) It was unclear how the team will guarantee a personalized and high quality content. That's probably the biggest risk inside\nyour product. (C) The budget is too small. You will definitely need more for 18-months period.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Syuzanna Avetisyan",
    "Feedback And Comments":
      "2bArmenian / Good presentation guys. Very patriotic and interesting product idea. There are a couple of comments. (A) The product might be a good idea for Armenia, since it doesn't require large investments. But it was not clear how would\nthe team replicate the success in other regions. (B) The team needs to think through how would the go-to-market strategy be outside Armenia? (C) How would you make a high quality content inside the platform? How would you\nguarantee that? (D) It wasn't clear enough from the presentation what was the killer feature of the product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Anna Martirosyan",
    "Feedback And Comments":
      "Masoor / Good presentation guys. There are a couple comments. (A) The mission is great and it seems like more of a social project rather than a billion dollar idea. (B) There were a lot of unsuccessful attempts like this before but hopefully\nyou guys will rock it. (C) It wasn't clear enough what's your go-to-market strategy for the international market. (D) You might want to find more effective revenue streams in order to build a repeatable and scalable business model.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Lilit Mkhitaryan",
    "Feedback And Comments":
      "Educatcher / Good presentation guys. However, there are a couple of things you can improve. (A) At the beginning there was some confusion regarding your problem space. You can definitely work to make it more clear. (B) The market \ndescription was unclear as well. (C) The team was confused about the product as well. Maybe you can focus more on the recommendation engine, rather than just selling books. (D) Monetization process was not well-developed.\nThere are definitely better ways you can make revenue.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Tatevik Khachatryan",
    "Feedback And Comments":
      "EduCatcher / Good presentation guys. However, there are a couple of things you can improve. (A) At the beginning there was some confusion regarding your problem space. You can definitely work to make it more clear. (B) The market \ndescription was unclear as well. (C) The team was confused about the product as well. Maybe you can focus more on the recommendation engine, rather than just selling books. (D) Monetization process was not well-developed.\nThere are definitely better ways you can make revenue.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Hovhannes Hakobyan",
    "Feedback And Comments":
      "Educatcher / Good presentation guys. However, there are a couple of things you can improve. (A) At the beginning there was some confusion regarding your problem space. You can definitely work to make it more clear. (B) The market \ndescription was unclear as well. (C) The team was confused about the product as well. Maybe you can focus more on the recommendation engine, rather than just selling books. (D) Monetization process was not well-developed.\nThere are definitely better ways you can make revenue.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Suren Khachatryan",
    "Feedback And Comments":
      "Interview Simulation / Awesome presentation. Great job guys. There are a couple of comments. (A) The beginning of the story was too long. You could definitely compress it. (B) The pricing model was not clear enough for the Armenian market. It seems\nlike strong professionals would want to charge more than that. (C) Seems like you have done a lot of work on the interviewee side but not sufficient work on the interviewer side. There is definitely a big room for improvement. (D)\nTry to recalculate your investment size. You definititely need more than that for the whole team to work full time and hire additional team members for 18 months.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Davit Arakelyan",
    "Feedback And Comments":
      "Greenity / Great presentation guys. We loved it. Overall the business model can work. There are a couple of comments. (A) Create a stronger go-to-market stratategy for entering the international market. (B) Make good estimates in terms of\nthe funding, at least for 18 years.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Haykanush Hovhannisyan",
    "Feedback And Comments":
      "Greenity / Great presentation guys. We loved it. Overall the business model can work. There are a couple of comments. (A) Create a stronger go-to-market stratategy for entering the international market. (B) Make good estimates in terms of\nthe funding, at least for 18 years.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Hovik Tamazyan",
    "Feedback And Comments":
      "Universal Digital Banking / Good presentation guys. There is definitely a market there. There are a couple of commets. (A) As you already know there are many risks associated with loan payment. You need to make a bit more persuasive model regarding \nhow would you tackle all those risks? (C) How risk-averse are the investors. How can you design a good go-to-market strategy for bringing those people to the product? (C) This product requires a lot of connections at the beginning. \nHow would you build the right network for it? (D) Would people still prefer this product with associated risks over going to a bank with good brand awareness? (E) You definitely need to reanalize the resources you need to start the\nproduct.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Gor Aleksanyan",
    "Feedback And Comments":
      'SimpList / Great presentation guys. We really liked it. The stortelling was just amazing. There are couple of comments. (A) You MUST sell the big vision of entering a HUGE market. (B) Never say "chgitem" during Q&A. Never! That\'s a red flag.\n(B) Make sure you sell how big your market can be. (C) There were some uncertainties regarding the financial part (cost and incomes), and the calculations seemed a bit unrealistic.',
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Anna Harutyunyan",
    "Feedback And Comments":
      "Keepy / Good presentation guy. A couple of comments. (A) There is definitely some work that needs to be done in terms of the business model. (B) You found a real problem. The big issue is the market size. Services like that are \nunfortunatelly very cheap in Armenia. If you make about 300AMD from each visit it will be hard to make up for your costs. Focus on finding other ways for monitization. (C) Think about a killer feature that would give an unfair\nadvantage over your competitors.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Haykanush Chobanyan",
    "Feedback And Comments":
      "Study / Good presentation guys. You are starting from a good niche. There are a couple of comments. (A) The market size seems too small. We do understand Armenian market is just for piloting purposes but you should DREAM BIG.\n(B) There was no clear go-to-market strategy for getting into outside Armenia. That's really important for building a scalable business model. (C) Also, you need to work a little bit more on the business model of the product. You \ncan try to think of additional monetization channels as well. (D) You can definitely make better financial projects.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Arman Petrosyan",
    "Feedback And Comments":
      "Study / Good presentation guys. You are starting from a good niche. There are a couple of comments. (A) The market size seems too small. We do understand Armenian market is just for piloting purposes but you should DREAM BIG.\n(B) There was no clear go-to-market strategy for getting into outside Armenia. That's really important for building a scalable business model. (C) Also, you need to work a little bit more on the business model of the product. You \ncan try to think of additional monetization channels as well. (D) You can definitely make better financial projects.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Anahit Muradyan",
    "Feedback And Comments":
      "BetterSelf! / Great presentation guys. We liked it. The highest product risk we see right now is whether the problem can be productized with behavior science or not. This might make the solution pretty challenging. You definitely need \nbehavior analysts as part of your team to guide you through that process. This, for sure, will enable better content development for the product. The good thing is that there is definitely huge interest in the market. On the final\nnote, you should work a bit more on understanding what would be a more efficient go-to-market strategy for your product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Ofelya Gevorgyan",
    "Feedback And Comments":
      "Smart lounge / Good presentation guys. There are a couple of comments. (A) It feels like you still need to do more researcha about your market. (B) There can definitely be some improvements in your business model. (C) If you want to grow\nlike crazy, you will need to get outside Armenia.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Arpi Hayrapetyan",
    "Feedback And Comments":
      "BetterSelf! / Great presentation guys. We liked it. The highest product risk we see right now is whether the problem can be productized with behavior science or not. This might make the solution pretty challenging. You definitely need \nbehavior analysts as part of your team to guide you through that process. This, for sure, will enable better content development for the product. The good thing is that there is definitely huge interest in the market. On the final\nnote, you should work a bit more on understanding what would be a more efficient go-to-market strategy for your product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Sebastia Isaghulyan",
    "Feedback And Comments":
      "SmartEducation / Good presentation guys. The problem is actually very big and the solution seems very promising. Great design, by the way. There are a couple of comments. (A) The team should work more on its business model. Working with\ngovernments can get pretty harsh and there could be better monetization models, such as parents. (B) It was unclear how the team will guarantee a personalized and high quality content. That's probably the biggest risk inside\nyour product. (C) The budget is too small. You will definitely need more for 18-months period.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Christine Petrosyan",
    "Feedback And Comments":
      "EPM / Good presentation guys. A lot of work has been done and there are a couple of comments. (A) The problem statement was not clear from the presentation. You can definitely work on making it easier. (B) It was not clear what\nthe MVP supposed to be at the beginning. (C) The team should work a bit more to understand how would they scale the business outside Armenia. (D) It was not clear what was the killer feature of the product. (E) A very little\ninformation is given in numbers from the business model and market size. There should be some clarity. (F) It felt like the product was too complicated from the first glance. (E) You can definitely make a stronger pitch and there is\na lot of room for great improvement.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Arusyak Zakharyan",
    "Feedback And Comments":
      "Trustme / Good presentation. There are a couple of comments. (A) It was unclear what your MVP was going to be. (B) We couldn't find a clear explanation of the killer feature of the product. (C) Armenian market is too small for a specific\npersonalizatio  just for Armenians. The market size is too small. Business-wise it would make more sense to target larger markets. (D) It was not very clear what was the business model and the global market size of the product. (E)\nIn terms of the product, there is going to be too much extra work for entering another market, such as Georgia. (E) There was no clear growth strategy for the product.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Levon Ghevondyan",
    "Feedback And Comments":
      "JustQR / Awesome presentation guys. Geat job. There are a couple of comments. (A) You have a good go-to-market strategy for the Armenian market, but how ca you scale the business? (B) What would be next steps in the product when it \ncomes to implement next features? - What's the great vision? (C) There are a lot of risks associated from bigger stakeholders such as banks, global application and software producers.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Anush Khojayan",
    "Feedback And Comments":
      "SmartEducation / Good presentation guys. The problem is actually very big and the solution seems very promising. Great design, by the way. There are a couple of comments. (A) The team should work more on its business model. Working with\ngovernments can get pretty harsh and there could be better monetization models, such as parents. (B) It was unclear how the team will guarantee a personalized and high quality content. That's probably the biggest risk inside\nyour product. (C) The budget is too small. You will definitely need more for 18-months period.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Tamara Harutyunyan",
    "Feedback And Comments":
      "ArmCraft / Good presentation guys. There are a couple of comments. (A) The marker seems too small. Please try to DREAM BIG. There could be a lot of potential ways to scale the business. (B) It's a bit unclear what was your go-to-market\nstrategy. (C) Seems more of a non-profit project and you need to work more on your business model. (D) Overall it seems a small market, targeted Armenians only with unclear breakeven strategy, low commission. We are also\nnot sure about advanced services, the target audience might have no money to order advanced services.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Mushegh Galstyan",
    "Feedback And Comments":
      "2bArmenian / Good presentation guys. Very patriotic and interesting product idea. There are a couple of comments. (A) The product might be a good idea for Armenia, since it doesn't require large investments. But it was not clear how would\nthe team replicate the success in other regions. (B) The team needs to think through how would the go-to-market strategy be outside Armenia? (C) How would you make a high quality content inside the platform? How would you\nguarantee that? (D) It wasn't clear enough from the presentation what was the killer feature of the product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Armine Samvelyan",
    "Feedback And Comments":
      "JustQR / Awesome presentation guys. Geat job. There are a couple of comments. (A) You have a good go-to-market strategy for the Armenian market, but how ca you scale the business? (B) What would be next steps in the product when it \ncomes to implement next features? - What's the great vision? (C) There are a lot of risks associated from bigger stakeholders such as banks, global application and software producers.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Liana Davoyan",
    "Feedback And Comments":
      "Edumate / Awesome presentation guys. Great job! There are a couple of things you can do to improve your product work. (A) It was not clear enough how you motivate more mentor engagement. Maybe, a better monetization could help. \n(B) There are also some risks involved with your tests - how to make sure they are not subjective and well-aligned to the customers' real needs? This is a solvable problem but you need to work a bit more on that. (C) The business\nmodel and the payment flow inside the product were a little bit unclear at the beginning. P.S. We LOVED your confidence.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Gayane Kharatyan",
    "Feedback And Comments":
      "CareerHub / Awesome presentation guys. There is definitely big market there outside Armenia. There are a couple of comments. (A) It is still unclear what's your killer feature. (B) Where are you going to concentrate more? - The Landing page \nbuilder? The ATS? The Analytics? Where are you going to concentrate more at the beginning? (C) You should work more on the go-to-market strategy for the US market. You might brand ambassadors and advisors from that region. \n(D) Not the best competitor analysis. You can definitely do better. Awesome pitch and slide deck. Good luck!",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Samvel Harutyunyan",
    "Feedback And Comments":
      "BerTar / Good presetation guys. The mission is good, the prototype is great. There are a couple of comments. (A) The team needs to work a bit more on its marketing strategy. The GTM and business model can be improved. (B) THe hardest\nthing will be to grow the network in the beginning, if you can manage that part, the rest will be much easier.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Ani Makaryan",
    "Feedback And Comments":
      "PharmaGuide / Great presentation guys. Good job. There are a couple of comments. (A) Make sure to have a good go-to-market strategy for outside Armenia. Like how would you get to the Georgian market? (B) Work a little bit more on the \nmonetization aspect. It felt like you didn't put enough thought on it. (C) There are some shared concerns about the target audience - can they actually use the services. The speech and the presentation were just great.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Karapet Hovsepyan",
    "Feedback And Comments":
      "2bArmenian / Good presentation guys. Very patriotic and interesting product idea. There are a couple of comments. (A) The product might be a good idea for Armenia, since it doesn't require large investments. But it was not clear how would\nthe team replicate the success in other regions. (B) The team needs to think through how would the go-to-market strategy be outside Armenia? (C) How would you make a high quality content inside the platform? How would you\nguarantee that? (D) It wasn't clear enough from the presentation what was the killer feature of the product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Heghine Balyan",
    "Feedback And Comments":
      "UteLove / Awesome presentation guys. You nailed it. Here are a couple of comments. (A) How would you make those rating less subjective? (B) If you want to become a billion dollar idea, unfortunately you must forget about Armenia and \nenter the US market, or somewhere else. (C) The go-to-market strategy was not really well shaped. The naming is very cool, by the way. Keep rocking guys!",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Lilit Poghosyan",
    "Feedback And Comments":
      "Interview Simulation / Awesome presentation. Great job guys. There are a couple of comments. (A) The beginning of the story was too long. You could definitely compress it. (B) The pricing model was not clear enough for the Armenian market. It seems\nlike strong professionals would want to charge more than that. (C) Seems like you have done a lot of work on the interviewee side but not sufficient work on the interviewer side. There is definitely a big room for improvement. (D)\nTry to recalculate your investment size. You definititely need more than that for the whole team to work full time and hire additional team members for 18 months.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Aram Zoryan",
    "Feedback And Comments":
      "Evento / Good presentation guys. There are a couple of comments. (A) We didnt' quite understand the necessity of digitilizing the process of this types of event organization. The success of your product will depend mostly on the quailty \nof the organization of the events, instead of the quality of your product. (C) There is definitely some human touch expected in these types of event organizations. (D) There is a lot of heavy operational work involved with this\nbusiness.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Sirush Shahgeldyan",
    "Feedback And Comments":
      "HandyMe / Great presentation guys. It was really good. A couple of comments. There needs to be some work on marketing strategy, business model and attracting the supply side. + Make better estimations on te investment side.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Araksya Yeprikyan",
    "Feedback And Comments":
      "PharmaGuide / Great presentation guys. Good job. There are a couple of comments. (A) Make sure to have a good go-to-market strategy for outside Armenia. Like how would you get to the Georgian market? (B) Work a little bit more on the \nmonetization aspect. It felt like you didn't put enough thought on it. (C) There are some shared concerns about the target audience - can they actually use the services. The speech and the presentation were just great.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Nare Harutyunyan",
    "Feedback And Comments":
      "Easy Tracking / Good presentation guys. There are a couple of comments. (A) The team relies a lot on the government as a stakeholder. That's pretty dangerous. (B) You might want to find a more effective business model that will charger \ncustomers even earlier.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Lilit Saribekyan",
    "Feedback And Comments":
      "Trustme / Good presentation. There are a couple of comments. (A) It was unclear what your MVP was going to be. (B) We couldn't find a clear explanation of the killer feature of the product. (C) Armenian market is too small for a specific\npersonalizatio  just for Armenians. The market size is too small. Business-wise it would make more sense to target larger markets. (D) It was not very clear what was the business model and the global market size of the product. (E)\nIn terms of the product, there is going to be too much extra work for entering another market, such as Georgia. (E) There was no clear growth strategy for the product.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Parandzem Voskanyan",
    "Feedback And Comments":
      "Smart Bus / Good presentation guys. There are a couple of comments. (A) There are a lot of dependencies from the government's side. What if they reject your idea? (B) A lot of technical work is required in terms of the real-time updates. This\nmight take a lot of infrastructure costs. (C) There was not feeling that the team was believing in scaling the business outside Armenia.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Mikael Nazaryan",
    "Feedback And Comments":
      "Wayst / Good presentation guys. There are a couple of comments. (A) The MVP version of the product seems very promising. However, it seems like the solution statement was a little bit hard to understand from the beginning. (C) The team \nneeds to work a little bit more on its marketing strategy. There was not well-defined go-to-market strategy.  (C) It's very good that you have an ambitious plan which in our opinion requires much higher investment. (D) Also you will \nencounter a lot of logistical issues and most probably need a math expert to help you with some calculations. This is an amazing idea, hope your start up will succeed.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Tatevik Manukyan",
    "Feedback And Comments":
      "Wayst / Good presentation guys. There are a couple of comments. (A) The MVP version of the product seems very promising. However, it seems like the solution statement was a little bit hard to understand from the beginning. (C) The team \nneeds to work a little bit more on its marketing strategy. There was not well-defined go-to-market strategy.  (C) It's very good that you have an ambitious plan which in our opinion requires much higher investment. (D) Also you will \nencounter a lot of logistical issues and most probably need a math expert to help you with some calculations. This is an amazing idea, hope your start up will succeed.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Shushan Margaryan",
    "Feedback And Comments":
      "ArmCraft / Good presentation guys. There are a couple of comments. (A) The marker seems too small. Please try to DREAM BIG. There could be a lot of potential ways to scale the business. (B) It's a bit unclear what was your go-to-market\nstrategy. (C) Seems more of a non-profit project and you need to work more on your business model. (D) Overall it seems a small market, targeted Armenians only with unclear breakeven strategy, low commission. We are also\nnot sure about advanced services, the target audience might have no money to order advanced services.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Ara Avagyan",
    "Feedback And Comments":
      "Smart Bus / Good presentation guys. There are a couple of comments. (A) There are a lot of dependencies from the government's side. What if they reject your idea? (B) A lot of technical work is required in terms of the real-time updates. This\nmight take a lot of infrastructure costs. (C) There was not feeling that the team was believing in scaling the business outside Armenia.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Lusine Shahnazaryan",
    "Feedback And Comments":
      "Interview Simulation / Awesome presentation. Great job guys. There are a couple of comments. (A) The beginning of the story was too long. You could definitely compress it. (B) The pricing model was not clear enough for the Armenian market. It seems\nlike strong professionals would want to charge more than that. (C) Seems like you have done a lot of work on the interviewee side but not sufficient work on the interviewer side. There is definitely a big room for improvement. (D)\nTry to recalculate your investment size. You definititely need more than that for the whole team to work full time and hire additional team members for 18 months.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Meline Hovhannisyan",
    "Feedback And Comments":
      "BerTar / Good presetation guys. The mission is good, the prototype is great. There are a couple of comments. (A) The team needs to work a bit more on its marketing strategy. The GTM and business model can be improved. (B) THe hardest\nthing will be to grow the network in the beginning, if you can manage that part, the rest will be much easier.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Dianna Galstyan",
    "Feedback And Comments":
      "Evento / Good presentation guys. There are a couple of comments. (A) We didnt' quite understand the necessity of digitilizing the process of this types of event organization. The success of your product will depend mostly on the quailty \nof the organization of the events, instead of the quality of your product. (C) There is definitely some human touch expected in these types of event organizations. (D) There is a lot of heavy operational work involved with this\nbusiness.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Diana Danielyan",
    "Feedback And Comments":
      "GainFactory / Good presentation guys. There are a couple of comments. (A)  The team needs to work a bit more on its marketing strategy. Start from focusing in just one market and then think about how you can expand. (B) GTM is not realistic, \ncompetition is much higher than team imagined. (C) It seems like the team has no unique proposition, game changing killer features",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Silvi Hambardzumyan",
    "Feedback And Comments":
      "Keepy / Good presentation guy. A couple of comments. (A) There is definitely some work that needs to be done in terms of the business model. (B) You found a real problem. The big issue is the market size. Services like that are \nunfortunatelly very cheap in Armenia. If you make about 300AMD from each visit it will be hard to make up for your costs. Focus on finding other ways for monitization. (C) Think about a killer feature that would give an unfair\nadvantage over your competitors.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Astghik Nalchajyan",
    "Feedback And Comments":
      "2bArmenian / Good presentation guys. Very patriotic and interesting product idea. There are a couple of comments. (A) The product might be a good idea for Armenia, since it doesn't require large investments. But it was not clear how would\nthe team replicate the success in other regions. (B) The team needs to think through how would the go-to-market strategy be outside Armenia? (C) How would you make a high quality content inside the platform? How would you\nguarantee that? (D) It wasn't clear enough from the presentation what was the killer feature of the product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Anush Sahakyan",
    "Feedback And Comments":
      "Careerhub / Awesome presentation guys. There is definitely big market there outside Armenia. There are a couple of comments. (A) It is still unclear what's your killer feature. (B) Where are you going to concentrate more? - The Landing page \nbuilder? The ATS? The Analytics? Where are you going to concentrate more at the beginning? (C) You should work more on the go-to-market strategy for the US market. You might brand ambassadors and advisors from that region. \n(D) Not the best competitor analysis. You can definitely do better. Awesome pitch and slide deck. Good luck!",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Christine Sargsyan",
    "Feedback And Comments":
      "BarBar / Good presentation guys. Maybe not a very business-oriented but more of a social project. There are couple of comments. (A) You guys need to work a little bit more on your business model and revenue streams. (B) Think of how\ncan you make a BIG VISION out of it. Sell big. It's normal to start from a small market, but there should be some scaling opportunities --> This is how you make a scalable and repeatable business model. (C) Think about how can \nyou increase the item offering inside the product to make better monetization out of it. (D) Work on making better market estimations - it's crucial. (E) You can definitely improve your pitch deck.",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Gayane Papinyan",
    "Feedback And Comments":
      "GainFactory / Good presentation guys. There are a couple of comments. (A)  The team needs to work a bit more on its marketing strategy. Start from focusing in just one market and then think about how you can expand. (B) GTM is not realistic, \ncompetition is much higher than team imagined. (C) It seems like the team has no unique proposition, game changing killer features",
    "Grade (/100)": 67,
  },
  {
    "Student Name": "Vardan Jangozyan",
    "Feedback And Comments":
      "Keepy / Good presentation guy. A couple of comments. (A) There is definitely some work that needs to be done in terms of the business model. (B) You found a real problem. The big issue is the market size. Services like that are \nunfortunatelly very cheap in Armenia. If you make about 300AMD from each visit it will be hard to make up for your costs. Focus on finding other ways for monitization. (C) Think about a killer feature that would give an unfair\nadvantage over your competitors.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Tatev Sevoyan",
    "Feedback And Comments":
      "MoneyWise / Good presentation guys. There are couple of comments. (A) It seems like the team needs to work a bit more to find more effective service approaches. (B) It will not probably replace our accounts, but it can definitely become our\nassistant. There is a big potential if you success in managerial tool development with business insights.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Narine Ghazaryan",
    "Feedback And Comments":
      "Easy tracking / Good presentation guys. There are a couple of comments. (A) The team relies a lot on the government as a stakeholder. That's pretty dangerous. (B) You might want to find a more effective business model that will charger \ncustomers even earlier.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Anna Simonyan",
    "Feedback And Comments":
      "Greenity / Great presentation guys. We loved it. Overall the business model can work. There are a couple of comments. (A) Create a stronger go-to-market stratategy for entering the international market. (B) Make good estimates in terms of\nthe funding, at least for 18 years.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Hasmik Ter-Grigoryan",
    "Feedback And Comments":
      'SimpList / Great presentation guys. We really liked it. The stortelling was just amazing. There are couple of comments. (A) You MUST sell the big vision of entering a HUGE market. (B) Never say "chgitem" during Q&A. Never! That\'s a red flag.\n(B) Make sure you sell how big your market can be. (C) There were some uncertainties regarding the financial part (cost and incomes), and the calculations seemed a bit unrealistic.',
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Satenik Movsesyan",
    "Feedback And Comments":
      'Simplist / Great presentation guys. We really liked it. The stortelling was just amazing. There are couple of comments. (A) You MUST sell the big vision of entering a HUGE market. (B) Never say "chgitem" during Q&A. Never! That\'s a red flag.\n(B) Make sure you sell how big your market can be. (C) There were some uncertainties regarding the financial part (cost and incomes), and the calculations seemed a bit unrealistic.',
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Sergey Safaryan",
    "Feedback And Comments":
      "Doctech / Good presentation guys. Awesome job. There is definitely a need in the market. Here are a couple of comments. (A) The market might not be very ready right now but it's getting there. (B) The go-to-market strategy was a bit\nunclear. (C) The financial breakeven seems unreachable with this business model. You can definitely improve it.",
    "Grade (/100)": 87,
  },
  {
    "Student Name": "Sona Hovsepyan",
    "Feedback And Comments":
      "EduMate / Awesome presentation guys. Great job! There are a couple of things you can do to improve your product work. (A) It was not clear enough how you motivate more mentor engagement. Maybe, a better monetization could help. \n(B) There are also some risks involved with your tests - how to make sure they are not subjective and well-aligned to the customers' real needs? This is a solvable problem but you need to work a bit more on that. (C) The business\nmodel and the payment flow inside the product were a little bit unclear at the beginning. P.S. We LOVED your confidence.",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Vardan Hovhannisyan",
    "Feedback And Comments":
      "UteLove / Awesome presentation guys. You nailed it. Here are a couple of comments. (A) How would you make those rating less subjective? (B) If you want to become a billion dollar idea, unfortunately you must forget about Armenia and \nenter the US market, or somewhere else. (C) The go-to-market strategy was not really well shaped. The naming is very cool, by the way. Keep rocking guys!",
    "Grade (/100)": 93,
  },
  {
    "Student Name": "Meri Torosyan",
    "Feedback And Comments":
      "Masoor / Good presentation guys. There are a couple comments. (A) The mission is great and it seems like more of a social project rather than a billion dollar idea. (B) There were a lot of unsuccessful attempts like this before but hopefully\nyou guys will rock it. (C) It wasn't clear enough what's your go-to-market strategy for the international market. (D) You might want to find more effective revenue streams in order to build a repeatable and scalable business model.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Ani Saghatelyan",
    "Feedback And Comments":
      "2bArmenian / Good presentation guys. Very patriotic and interesting product idea. There are a couple of comments. (A) The product might be a good idea for Armenia, since it doesn't require large investments. But it was not clear how would\nthe team replicate the success in other regions. (B) The team needs to think through how would the go-to-market strategy be outside Armenia? (C) How would you make a high quality content inside the platform? How would you\nguarantee that? (D) It wasn't clear enough from the presentation what was the killer feature of the product.",
    "Grade (/100)": 80,
  },
  {
    "Student Name": "Anna Galstyan",
    "Feedback And Comments":
      "Study / Good presentation guys. You are starting from a good niche. There are a couple of comments. (A) The market size seems too small. We do understand Armenian market is just for piloting purposes but you should DREAM BIG.\n(B) There was no clear go-to-market strategy for getting into outside Armenia. That's really important for building a scalable business model. (C) Also, you need to work a little bit more on the business model of the product. You \ncan try to think of additional monetization channels as well. (D) You can definitely make better financial projects.",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Anna Khachatryan",
    "Feedback And Comments": "",
    "Grade (/100)": 73,
  },
  {
    "Student Name": "Lena Petrosyan",
    "Feedback And Comments":
      "JustQR / Awesome presentation guys. Geat job. There are a couple of comments. (A) You have a good go-to-market strategy for the Armenian market, but how ca you scale the business? (B) What would be next steps in the product when it \ncomes to implement next features? - What's the great vision? (C) There are a lot of risks associated from bigger stakeholders such as banks, global application and software producers.",
    "Grade (/100)": 93,
  },
];

finalProject.name = "Final Project";

const homeworks = [
  hwk1,
  hwk2,
  hwk3,
  hwk4,
  hwk5,
  hwk6,
  midterm,
  exam,
  finalProject,
  finalScore,
];

function Homework({ results }) {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [userResult, setUserResult] = useState(
    results.find((u) => u["Student Name"] === user.displayName)
  );

  const handleSubmit = () => {
    if (password.trim() === user.uid) {
      console.log("here");
      const newUser = results.find(
        (u) => u["Student Name"].toUpperCase() === name.trim().toUpperCase()
      );
      console.log("here2", newUser, results);
      if (!newUser) {
        notification.open({
          type: "error",
          message: "Sorry, something went wrong!",
          description: "Please, contact ACA Team!",
        });
      }
      setUserResult(newUser);
    } else {
      notification.open({
        type: "error",
        message: "Sorry, something went wrong!",
        description: "Please, contact ACA Team!",
      });
    }
  };

  if (!userResult) {
    return (
      <div>
        <h2>Holy guacamole! There's no result 🙄</h2>
        <p style={{ marginBottom: 2 }}>
          Maybe your name in gmail account is incorrect.
        </p>
        <p>
          Please, write the name that you've mentioned submitting the form, so
          we will try to find your results!
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 320,
            marginTop: 40,
          }}
        >
          <Input
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Name (e.g. Vrezh Oganisyan)"
            style={{ maxWidth: 320, marginRight: 10, marginBottom: 15 }}
          />
          <Input
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Password (find in homepage)"
            style={{ maxWidth: 320, marginRight: 10, marginBottom: 25 }}
          />
          <Button onClick={handleSubmit} type="primary">
            Find Result!
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Card
      title={`Grade: ${userResult["Grade (/100)"]} / 100`}
      bordered={false}
      style={{ maxWidth: 640 }}
    >
      {userResult["Feedback And Comments"] && <h2>Comment</h2>}
      {(userResult["Feedback And Comments"] || "")
        .split("\n")
        .map((content) => (
          <p>{content}</p>
        ))}
    </Card>
  );
}

export default function Homeworks() {
  return (
    <Row>
      <Col span={24} lg={12}>
        <Typography.Title>Homeworks</Typography.Title>
        {homeworks.map((results, idx) => (
          <Collapse collapsible={true} style={{ marginBottom: 15 }}>
            <Collapse.Panel
              header={results.name || `Homework #${idx + 1}`}
              key={idx}
            >
              <Homework results={results} />
            </Collapse.Panel>
          </Collapse>
        ))}
      </Col>
    </Row>
  );
}
