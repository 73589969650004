import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import cn from "classnames";
import { getAllAdmins } from "../../../services/admins.services";

import { Logo, LogOut } from "../Icons/Icons";
import Flex from "../Flex/Flex";
import "./Layout.css";
import { Routes } from "../../../helpers/routes.helpers";
import { useAuth } from "../../../services/authentication.services";
import useWindowSize from "../../../hooks/useWindowSize";
import useOutsideClick from "../../../hooks/useOutsideClick";
import * as Icons from "../Icons/Icons";

const MenuSubtitle = ({ children }) => (
  <p className="menu-subtitle">{children}</p>
);

export default function Layout({ title, children, isAdmin }) {
  const { user, signout } = useAuth();
  const history = useHistory();
  const size = useWindowSize();
  const [activeUrl, setActiveUrl] = useState(history.location.pathname);
  const [showMenu, setShowMenu] = useState(true);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (size.width < 740) {
      setShowMenu(false);
    }
  });

  useEffect(() => {
    if (size.width > 740) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [size.width]);

  history.listen((location) => {
    setActiveUrl(location.pathname);
  });

  return (
    <div className="main-wrapper">
      <Flex
        style={{
          width: "100%",
          padding: "10px 30px",
          backgroundColor: "#fff",
        }}
        alignItems="center"
        justifyContent="center"
      >
        {size.width < 740 && (
          <Icons.Burger
            onClick={() => {
              setShowMenu((m) => !m);
            }}
            color="#000"
            style={{ cursor: "pointer", marginRight: 20 }}
          />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            margin: "10px 0px",
          }}
        >
          <Logo
            width="298"
            color="#fff"
            onClick={() => history.push(Routes.home())}
          />
        </div>

        <Flex
          style={{
            display: size.width < 600 ? "none" : "block",
            marginRight: "33px",
            marginLeft: "auto",
          }}
          direction="column"
        >
          <p style={{ margin: 0, lineHeight: 1, marginBottom: 4 }}>
            {user.displayName || user.email}
          </p>
        </Flex>
        <LogOut
          style={{
            cursor: "pointer",
            marginLeft: size.width < 600 ? "auto" : "0",
          }}
          onClick={signout}
        />
      </Flex>
      <Flex>
        <div ref={ref}>
          {showMenu && (
            <Flex
              className="main-nav-wrapper"
              style={{
                position: size.width < 740 ? "fixed" : "static",
                left: 0,
                top: 80,
                zIndex: 10000,
              }}
              direction="column"
            >
              <Link
                className={cn("nav-link", {
                  active: Routes.home() === activeUrl,
                })}
                onClick={() => {
                  if (size.width < 740) {
                    setShowMenu(false);
                  }
                }}
                to={Routes.home()}
              >
                Home
              </Link>
              {isAdmin && (
                <Link
                  onClick={() => {
                    if (size.width < 740) {
                      setShowMenu(false);
                    }
                  }}
                  className={cn("nav-link", {
                    active: Routes.admin() === activeUrl,
                  })}
                  to={Routes.admin()}
                >
                  Admin
                </Link>
              )}
              {isAdmin && (
                <Link
                  onClick={() => {
                    if (size.width < 740) {
                      setShowMenu(false);
                    }
                  }}
                  className={cn("nav-link", {
                    active: Routes.absentees() === activeUrl,
                  })}
                  to={Routes.absentees()}
                >
                  Absentees
                </Link>
              )}
              <MenuSubtitle type="secondary">Infrastructure</MenuSubtitle>
              <Link
                onClick={() => {
                  if (size.width < 740) {
                    setShowMenu(false);
                  }
                }}
                className={cn("nav-link", {
                  active: Routes.homeworks() === activeUrl,
                })}
                to={Routes.homeworks()}
              >
                Homeworks
              </Link>
            </Flex>
          )}
        </div>

        <div
          style={{
            width: "100%",
            marginTop: 24,
            marginRight: 30,
            marginLeft: size.width < 740 ? 30 : 0,
            maxHeight: "calc(100vh - 145px)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {children}
        </div>
      </Flex>
    </div>
  );
}
