import React, { useContext, useEffect } from "react";
import { getAllAdmins } from "../services/admins.services";
import { useAuth } from "../services/authentication.services";
import { Spin, Table, Typography } from "antd";
import { Routes } from "../helpers/routes.helpers";
import { Link } from "react-router-dom";
import { groupBy } from "lodash";
import { AdminContext } from "../App";
import { absentees } from "../constants/absentees";

const { Title } = Typography;

const groupedByEmail = groupBy(absentees, "email");
const headers = Object.keys(groupedByEmail);
const groupedByDate = groupBy(absentees, "date");
const dates = Object.keys(groupBy(absentees, "date"));

export default function Absentees() {
  const { user } = useAuth();
  const isAdmin = useContext(AdminContext);

  useEffect(() => {
    getAllAdmins().then((admins) => {
      const isAdmin = admins.find((a) => a?.email === user.email);

      if (isAdmin) {
      } else {
      }
    });
  }, []);

  return (
    <Spin spinning={isAdmin === null}>
      {isAdmin ? (
        <>
          <p>Absentees</p>
          {/* <table>
            {headers.map((key) => (
              <>
                <p>{key}</p>
                {data[key].map(({ name }) => (
                  <span>{name}</span>
                ))}
              </>
            ))}
          </table> */}
          <table>
            <tr>
              <th>Email / Date</th>
              <th>Total</th>
              {dates.map((date) => (
                <th scope="col">{date}</th>
              ))}
            </tr>
            {headers.map((header) => {
              return (
                <tr>
                  <th scope="row">
                    {header} — {groupedByEmail[header].name}
                  </th>
                  <tr>
                    {dates.map((date) => {
                      return (
                        <td>
                          {() => {
                            console.log(groupedByDate[date]);
                            const isThere = groupedByDate[date].find(
                              (u) => u.email === groupedByEmail[header].email
                            );

                            return isThere ? "+" : "-";
                          }}
                        </td>
                      );
                    })}
                  </tr>
                </tr>
              );
            })}
          </table>
        </>
      ) : (
        <>
          <Title>You are not an admin!</Title>
          <Link to={Routes.home()}>Return to Home Page</Link>
        </>
      )}
    </Spin>
  );
}
