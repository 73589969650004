import React, { useState } from "react";
import { Form, Input, Button, Spin, Typography, notification } from "antd";
import { MailFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import styles from "./Login.module.css";
import { useAuth } from "../services/authentication.services";
import { Routes } from "../helpers/routes.helpers";

const { Title, Text } = Typography;

const tailLayout = {
  wrapperCol: { span: 32 },
};

export default function Login() {
  const [formStatus, setFormStatus] = useState("idle");
  const history = useHistory();
  const { authWithGoogle } = useAuth();

  const onFinish = async () => {
    setFormStatus("loading");
    try {
      await authWithGoogle();
      history.push(Routes.home());
    } catch (e) {
      notification.open({
        type: "error",
        message: "Something went wrong! Please, try again!",
        description: e.message,
      });
      setFormStatus("idle");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.login}>
        <Title style={{ textAlign: "center" }}>Log In</Title>
        <Text type="secondary">Website for watching ACA Recordings</Text>

        <Spin spinning={formStatus === "loading"} tip="Loading...">
          <Form
            className={styles.form}
            layout="vertical"
            name="login"
            onFinish={onFinish}
          >
            {/* <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                size="large"
                placeholder="large size"
                prefix={<UserOutlined color="blue" />}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                type="password"
                size="large"
                placeholder="Password"
                prefix={<LockTwoTone fill="blue" />}
              />
            </Form.Item> */}

            <Form.Item {...tailLayout}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className={styles.button}
              >
                Login via Gmail <MailFilled />
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
